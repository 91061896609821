import { Injectable } from "@angular/core";
import { WindowService } from "../../window";
import { Router } from "@angular/router";

const REDIRECT_KEYNAME = "_r";

@Injectable({
    providedIn: "root",
})
export class RedirectorService {
    private redirectUrl: string | null = null;
    errorMsg?: string;

    constructor(
        private windowService: WindowService,
        private router: Router,
    ) {
        this.init();
    }

    private init(): void {
        this.redirectUrl = this.windowService.getKeyValue(REDIRECT_KEYNAME) as string | null;
    }

    setRedirect(redirectUrl: string): void {
        this.redirectUrl = redirectUrl;
        this.windowService.setKeyValue(REDIRECT_KEYNAME, redirectUrl);
    }

    clearRedirect(): void {
        this.redirectUrl = null;
        this.windowService.removeKeyValue(REDIRECT_KEYNAME);
    }

    followRedirectIfSet(): boolean {
        if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
            this.clearRedirect();
            return true;
        }
        return false;
    }
}
