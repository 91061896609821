import { Injectable } from "@angular/core";

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppUser } from "../../models/app-user";
import { AppUserActions } from "../../store/actions";

@Injectable()
export class Http401Interceptor implements HttpInterceptor {
    constructor(private globalStore: Store<{ appUser: AppUser }>) {}
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<unknown>) => event),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    //user is no longer authenticated
                    this.globalStore.dispatch(AppUserActions.logout());
                }
                return throwError(error);
            }),
        );
    }
}
