<div class="page-wrapper logout-wrapper">
    <div class="page-content">
        <h1 class="pb-4">{{ strings.appLongName }}</h1>
        <mat-card appearance="outlined">
            <mat-card-content class="flex flex-col items-center place-content-center">
                <h3 class="text-lg pb-4">{{ signoutMessage }}</h3>
                <button mat-flat-button color="primary" (click)="login()">Log in</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>
