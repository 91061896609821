<h2 mat-dialog-title>Manage Name</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput [formControl]="form.controls.firstName" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput [formControl]="form.controls.lastName" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
    <button
        mat-flat-button
        color="primary"
        [mat-dialog-close]="this.form.getRawValue()"
        cdkFocusInitial
        [disabled]="form.invalid"
    >
        Save Name
    </button>
</mat-dialog-actions>
