import { Injectable } from "@angular/core";
import { ConfirmDialogComponent } from "../components/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DialogData } from "../models/dialog-data";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DialogsService {
    constructor(private dialog: MatDialog) {}

    public open(data: DialogData, width = 400): Observable<boolean> {
        return this.dialog
            .open(ConfirmDialogComponent, {
                width: `${width}px`,
                data: data,
            })
            .afterClosed();
    }

    public alert(message: string, title?: string | undefined): void {
        this.open({
            title,
            message,
            hideCancelButton: true,
        });
    }

    public confirm(
        message: string,
        title?: string | undefined,
        okayTitle?: string | undefined,
        cancelTitle?: string | undefined,
        width?: number | undefined,
    ): Observable<boolean> {
        return this.open(
            {
                title,
                message,
                okayTitle,
                cancelTitle,
            },
            width,
        );
    }
}
