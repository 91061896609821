import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class WindowService {
    /* istanbul ignore next */
    public gotoURL(url: string): void {
        window.location.assign(url);
    }

    /* istanbul ignore next */
    public getCurrentBrowserPath(): string {
        return document.location.pathname;
    }

    /* istanbul ignore next */
    public removeKeyValue(key: string): void {
        window.localStorage.removeItem(key);
    }

    /* istanbul ignore next */
    public setKeyValue(key: string, value: string | number | null | Record<string, unknown>): void {
        if (value === null) {
            window.localStorage.removeItem(key);
            return;
        }
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    /* istanbul ignore next */
    public getKeyValue(key: string): string | number | null | Record<string, unknown> {
        const value: string | null = window.localStorage.getItem(key);
        return value ? JSON.parse(value) : value;
    }

    /* istanbul ignore next */
    public newFileReader(): FileReader | null {
        if (typeof FileReader === "undefined") {
            return null;
        }
        return new FileReader();
    }
}
