import { Injectable } from "@angular/core";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DownloaderService {
    public downloadResponse(response: HttpResponse<Blob | string>, filename: string): void {
        if (response.body === null) {
            return;
        }
        const a: HTMLAnchorElement = document.createElement("a");
        a.setAttribute("style", "display:none;");
        // istanbul ignore next
        a.href = URL.createObjectURL(
            new Blob([response.body], { type: response.headers.get("Content-Type") ?? undefined }),
        );
        a.download = filename;
        a.click();
        setTimeout(() => {
            a.remove();
        }, 500);
    }

    public downloadObserver(observer: Observable<HttpResponse<Blob | string>>, filename: string): void {
        observer.subscribe((response) => {
            this.downloadResponse(response, filename);
        });
    }

    public download(body: string, filename: string, mimetype = "text/csv"): void {
        const headers: HttpHeaders = new HttpHeaders();
        headers.set("Content-Type", mimetype);
        const response: HttpResponse<string> = {
            body: body,
            headers: headers,
        } as HttpResponse<string>;
        this.downloadResponse(response, filename);
    }
}
