import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LetDirective } from "@ngrx/component";
import { MaterialModule } from "../../material.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FiltersModule } from "../shared/filters";
import { ViewComponent } from "./components/view/view.component";
import { MatSidenav } from "@angular/material/sidenav";
import { AppLogsListModule } from "../shared/logs";
import { DialogManageComponent } from "./components/dialog-manage/dialog-manage.component";
import { DialogReassignEmailComponent } from "./components/dialog-reassign-email/dialog-reassign-email.component";
import { DialogAddPermissionComponent } from "./components/dialog-add-permission/dialog-add-permission.component";
import { DialogApiKeyComponent } from "./components/dialog-api-key/dialog-api-key.component";
import { DialogLogSubscriptionsComponent } from "./components/dialog-log-subscriptions/dialog-log-subscriptions.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { AppCopyToClipboardModule } from "../shared/copy-to-clipboard/components";
import { SharedPipesModule } from "../shared/pipes";

const routes: Routes = [
    {
        path: ":uuid",
        component: ViewComponent,
    },
    {
        path: "",
        component: ViewComponent,
    },
];

@NgModule({
    declarations: [
        ViewComponent,
        DialogManageComponent,
        DialogReassignEmailComponent,
        DialogAddPermissionComponent,
        DialogApiKeyComponent,
        DialogLogSubscriptionsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
        LetDirective,
        MaterialModule,
        FormsModule,
        FontAwesomeModule,
        FiltersModule,
        AppLogsListModule,
        ClipboardModule,
        AppCopyToClipboardModule,
        SharedPipesModule,
    ],
    exports: [],
    providers: [MatSidenav, DatePipe],
})
export class AppProfilesModule {}
