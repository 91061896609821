import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UsersService } from "@hydrantid/acm-client";
import { first } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { MessageService } from "../../../../services/message/message.service";
import { CachedDataService } from "../../../../services/cached-data/cached-data.service";

interface SubscriptionOption {
    control: FormControl;
    name: string;
}

export interface DialogLogSubscriptionData {
    userId: string;
    subscriptions: string[];
}

@Component({
    selector: "app-dialog-log-subscriptions",
    templateUrl: "./dialog-log-subscriptions.component.html",
    styleUrls: ["./dialog-log-subscriptions.component.scss"],
})
export class DialogLogSubscriptionsComponent {
    options: SubscriptionOption[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogLogSubscriptionData,
        private dialogRef: MatDialogRef<DialogLogSubscriptionsComponent>,
        private cachedDataService: CachedDataService,
        public usersService: UsersService,
        private messageService: MessageService,
    ) {
        this.cachedDataService.subscriptionTypes$.pipe(first()).subscribe((results) => {
            for (const subscription of results) {
                this.options.push({
                    name: subscription,
                    control: new FormControl(data.subscriptions.indexOf(subscription) >= 0),
                });
            }
        });
    }

    save(): void {
        const newSubscriptions: string[] = this.options
            .filter((option) => option.control.value)
            .map((option) => option.name);

        this.usersService.usersIdSubscriptionsPost(this.data.userId, newSubscriptions).subscribe(
            (result) => this.dialogRef.close(result),
            (error) => this.messageService.showError(error.error),
        );
    }
}
