import { ActionReducer } from "@ngrx/store/src/models";
import { Action, createReducer, on } from "@ngrx/store";
import { UsersActions } from "./actions";
import { AvailableUsers } from "../state";

export const initialState: AvailableUsers = {
    users: [],
    filterValue: undefined,
};

const _featureReducer: ActionReducer<AvailableUsers> = createReducer<AvailableUsers>(
    initialState,
    on(UsersActions.setUsers, (state: AvailableUsers, { users }) => ({ ...state, users })),
    on(UsersActions.setFilterValue, (state: AvailableUsers, { filterValue }) => ({ ...state, filterValue })),
);

export function featureReducer(state: AvailableUsers, action: Action): AvailableUsers {
    return _featureReducer(state, action);
}
