import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppUser } from "../../models/app-user";
import { AppUserActions } from "../../store/actions";

@Component({
    selector: "app-eula",
    templateUrl: "./eula.component.html",
    styleUrls: ["./eula.component.scss"],
})
export class EulaComponent {
    constructor(private globalStore: Store<{ appUser: AppUser }>) {}

    accept(): void {
        this.globalStore.dispatch(AppUserActions.acceptEula());
    }
}
