<ng-container *ngrxLet="viewStore.vm$; let vm">
    <ng-container *ngIf="promptAssign">
        <h2 mat-dialog-title class="!pb-0">Reassign Notification Emails and Certificates</h2>
        <mat-hint class="px-6"
            >Reassign the notification emails and certificates that are assigned to the current owner.</mat-hint
        >
    </ng-container>
    <ng-container *ngIf="promptConfirm">
        <h2 mat-dialog-title class="!pb-0">Confirm Reassignment</h2>
        <mat-hint class="px-6"
            >Please confirm that you would like to reassign the notification emails<span
                *ngIf="!!includeCertificates.value"
            >
                and certificates</span
            >
            to a new owner.</mat-hint
        >
    </ng-container>
    <mat-dialog-content>
        <div *ngIf="!vm.email">Loading certificate count...</div>
        <div *ngIf="promptAssign && vm.email">
            <div class="cert-count-wrapper">
                <div class="flex flex-row gap-4">
                    <div class="stacked-label flex-auto">
                        <label>Current Owner</label>
                        {{ vm.email }}
                    </div>
                    <div class="stacked-label text-center flex-none">
                        <label>Email Count</label>
                        {{ vm.expiryEmailCount }}
                    </div>
                    <div class="stacked-label text-center flex-none">
                        <label>Certificate Count</label>
                        {{ vm.certificatesCount }}
                    </div>
                </div>
            </div>

            <div *ngIf="vm.expiryEmailCount > 0 || vm.certificatesCount > 0">
                <div class="mat-checkbox-wrapper flex flex-col">
                    <mat-checkbox color="primary" [(ngModel)]="includeNotificationEmails" [disabled]="true">
                        Reassign Notification Emails
                    </mat-checkbox>
                    <mat-checkbox color="primary" [formControl]="includeCertificates">
                        Reassign Certificates
                    </mat-checkbox>
                </div>

                <div>
                    <mat-form-field>
                        <mat-label>Select New Owner</mat-label>
                        <input
                            matInput
                            [formControl]="form.controls.user"
                            placeholder="Search by email address"
                            [matAutocomplete]="user"
                            (blur)="checkUser()"
                            required
                        />
                        <mat-autocomplete
                            #newOwner
                            autoActiveFirstOption
                            #user="matAutocomplete"
                            [displayWith]="formatUserEmailPipe.transform"
                        >
                            <mat-option *ngFor="let user of viewStore.filteredUsers$ | async" [value]="user">{{
                                user | formatUserEmail
                            }}</mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="this.form.controls.user.errors?.required">New Owner is required</mat-error>
                        <mat-error *ngIf="this.form.controls.user.errors?.selectFromList"
                            >Select a new owner from the list</mat-error
                        >
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="promptConfirm && vm.email" class="flex flex-col gap-4">
            <div class="flex flex-row items-center place-content-between gap-4">
                <div class="stacked-label">
                    <label>Current Owner</label>
                    {{ vm.email }}
                </div>
                <div class="stacked-label">
                    <label>New Owner</label>
                    {{ newOwnerEmail }}
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button color="primary" [mat-dialog-close]>Cancel</button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="disableForm(vm.expiryEmailCount, vm.certificatesCount)"
            (click)="saveReassign()"
            *ngIf="promptAssign"
        >
            Reassign
        </button>
        <button mat-flat-button color="primary" (click)="saveReassign()" *ngIf="promptConfirm">Confirm</button>
    </mat-dialog-actions>
</ng-container>
