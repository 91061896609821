import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { EffectsModule } from "@ngrx/effects";
import { AccountEffects } from "./store/effects";

import * as AccountSelectors from "./store/selectors";
import { CommonModule } from "@angular/common";
import { LetDirective } from "@ngrx/component";
export { AccountSelectors };
export { AccountActions } from "./store/actions";

@NgModule({
    imports: [
        CommonModule,
        LetDirective,
        StoreModule.forFeature("accounts", featureReducer),
        EffectsModule.forFeature([AccountEffects]),
    ],
})
export class AccountsModule {}
