<h2 mat-dialog-title>Add Account Role</h2>
<mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="savePermission()">
        <div class="flex flex-col gap-1">
            <mat-form-field>
                <input
                    matInput
                    [formControl]="form.controls.account"
                    placeholder="Select an Account"
                    [matAutocomplete]="account"
                />
                <mat-autocomplete #account="matAutocomplete" [displayWith]="accountFilterState.displayName">
                    <mat-option
                        *ngFor="let account of accountFilterState.activeFilteredAccounts$ | async"
                        [value]="account"
                        >{{ account.name }}</mat-option
                    >
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field *ngIf="accountFilterState.selectedAccount$ | async">
                <input
                    matInput
                    [formControl]="form.controls.organization"
                    placeholder="Select an Organization"
                    [matAutocomplete]="organization"
                />
                <mat-autocomplete #organization="matAutocomplete" [displayWith]="accountFilterState.displayName">
                    <mat-option
                        *ngFor="let organization of accountFilterState.filteredOrganizations$ | async"
                        [value]="organization"
                        >{{ organization.name }}</mat-option
                    >
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Role</mat-label>
                <mat-select [formControl]="form.controls.role">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let role of availableRoles$ | async" [value]="role">{{ role }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="form.invalid" (click)="savePermission()">
        Add Account Role
    </button>
</mat-dialog-actions>
