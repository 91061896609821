import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AccountActions } from "./actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { AccountService, Account } from "@hydrantid/acm-client";
import { AppUser, AppUserSelectors } from "../../app-user";

@Injectable()
export class AccountEffects {
    loadAccounts$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountActions.loadAccounts),
            mergeMap(() => {
                return this.accountsService.accountsNestedParanoidGet(true, false).pipe(
                    map((accounts) => {
                        return AccountActions.setAccounts({ accounts });
                    }),
                    catchError(() => of({ type: "error" })),
                );
            }),
        );
    });

    disableAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountActions.disableAccount),
            mergeMap((action) => {
                const account: Account = {
                    ...action.account,
                };
                return this.accountsService.accountsIdDelete(account.id).pipe(
                    map((result) => {
                        if (result.deleted) {
                            // @ts-ignore
                            account.deletedAt = new Date();
                        }
                        return AccountActions.updateAccountDeletedAt({ account: account });
                    }),
                );
            }),
        );
    });

    enableAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AccountActions.enableAccount),
            mergeMap((action) => {
                const account: Account = {
                    ...action.account,
                };
                // @ts-ignore
                return this.accountsService.accountsIdPut(account.id, { enable: true }).pipe(
                    map(() => {
                        // @ts-ignore
                        account.deletedAt = undefined;
                        return AccountActions.updateAccountDeletedAt({ account });
                    }),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private accountsService: AccountService,
        private globalStore: Store<{ appUser: AppUser }>,
    ) {
        this.globalStore.select(AppUserSelectors.user).subscribe((user) => {
            if (user) {
                setTimeout(() => {
                    globalStore.dispatch(AccountActions.loadAccounts());
                }, 0);
            }
        });
    }
}
