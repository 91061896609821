import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { EffectsModule } from "@ngrx/effects";
import { PolicyEffects } from "./store/effects";

import * as PolicySelectors from "./store/selectors";
import { CommonModule } from "@angular/common";
import { LetDirective } from "@ngrx/component";
export { PolicySelectors };
export { PolicyActions } from "./store/actions";

@NgModule({
    imports: [
        CommonModule,
        LetDirective,
        StoreModule.forFeature("policies", featureReducer),
        EffectsModule.forFeature([PolicyEffects]),
    ],
})
export class PoliciesModule {}
