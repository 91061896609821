import { AfterViewInit, ChangeDetectorRef, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import * as LoadingStatusSelectors from "../store/selectors";
import { IconsService } from "../../../../services/icons/icons.service";

@Component({
    selector: "app-loader",
    templateUrl: "./loader.component.html",
    styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements AfterViewInit {
    isLoading = false;

    constructor(
        public icons: IconsService,
        private globalStore: Store<{ loadingStatus: number }>,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    ngAfterViewInit(): void {
        //Unfortunately can't do this as a selector and async in the view because we get an expression changed
        //error
        this.globalStore.select(LoadingStatusSelectors.isLoading).subscribe((result) => {
            this.isLoading = result;
            this.changeDetectorRef.detectChanges();
        });
    }
}
