import { Policy } from "@hydrantid/acm-client";
import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { PolicyState } from "../state";

export interface AppState {
    policies: PolicyState;
}

export const policyState: MemoizedSelector<AppState, PolicyState> = createFeatureSelector("policies");
export const policies: MemoizedSelector<AppState, Policy[]> = createSelector(policyState, (policyState) => {
    return policyState.policies;
});
export const importedPolicies: MemoizedSelector<AppState, Policy[]> = createSelector(policyState, (policyState) => {
    return policyState.importedPolicies;
});
