import { UserWithRoles } from "@hydrantid/acm-client";
import { Selector } from "@ngrx/store";
import { AvailableUsers } from "../state";

interface AppState {
    availableUsers: AvailableUsers;
}

export const users: Selector<AppState, UserWithRoles[]> = (state: AppState) => state.availableUsers.users;

export const filterValue: Selector<AppState, string | undefined> = (state: AppState) =>
    state.availableUsers.filterValue;

export const filteredUsers: Selector<AppState, UserWithRoles[]> = (state: AppState) => {
    if (!state.availableUsers.filterValue) {
        return state.availableUsers.users;
    }
    const lowerFilterValue = state.availableUsers.filterValue.toLowerCase();
    const results = state.availableUsers.users.filter(
        (user) => user.email && user.email.toLocaleLowerCase().indexOf(lowerFilterValue) >= 0,
    );
    return results;
};
