import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { EffectsModule } from "@ngrx/effects";
import { CertificateAuthorityEffects } from "./store/effects";

import * as CertificateAuthoritySelectors from "./store/selectors";
import { CommonModule } from "@angular/common";
import { LetDirective } from "@ngrx/component";
export { CertificateAuthoritySelectors };
export { CertificateAuthorityActions } from "./store/actions";

@NgModule({
    imports: [
        CommonModule,
        LetDirective,
        StoreModule.forFeature("certificateAuthorities", featureReducer),
        EffectsModule.forFeature([CertificateAuthorityEffects]),
    ],
})
export class CertificateAuthoritiesModule {}
