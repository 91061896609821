import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { PolicyActions } from "./actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { PoliciesService } from "@hydrantid/acm-client";
import { AppUser, AppUserSelectors } from "../../app-user";

@Injectable()
export class PolicyEffects {
    loadPolicies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PolicyActions.loadPolicies),
            mergeMap(() => {
                return this.policiesService.policiesGet().pipe(
                    map((policies) => {
                        return PolicyActions.setPolicies({ policies });
                    }),
                    catchError(() => {
                        return of({ type: "error" });
                    }),
                );
            }),
        );
    });

    loadImportedPolicies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PolicyActions.loadPolicies),
            mergeMap(() => {
                return this.policiesService.policiesGet("only").pipe(
                    map((importedPolicies) => {
                        return PolicyActions.setImportedPolicies({ importedPolicies });
                    }),
                    catchError(() => {
                        return of({ type: "error" });
                    }),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private policiesService: PoliciesService,
        private globalStore: Store<{ appUser: AppUser }>,
    ) {
        this.globalStore.select(AppUserSelectors.pagePermissions).subscribe((permissions) => {
            if (permissions?.certificates) {
                setTimeout(() => {
                    globalStore.dispatch(PolicyActions.loadImportedPolicies());
                    globalStore.dispatch(PolicyActions.loadPolicies());
                }, 0);
            }
        });
    }
}
