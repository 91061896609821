import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LetDirective } from "@ngrx/component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatSidenav } from "@angular/material/sidenav";
import { DialogDetailsComponent } from "./components/dialog-details/dialog-details.component";
import { LogsListComponent } from "./components/list/list.component";
import { MaterialModule } from "../../../material.module";
import { FiltersModule } from "../filters";

@NgModule({
    declarations: [DialogDetailsComponent, LogsListComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        LetDirective,
        MaterialModule,
        FormsModule,
        FontAwesomeModule,
        FiltersModule,
    ],
    exports: [LogsListComponent],
    providers: [MatSidenav, DatePipe],
})
export class AppLogsListModule {}
