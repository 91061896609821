import { Account } from "@hydrantid/acm-client";
import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";

export interface AppState {
    accounts: Account[];
}

export const accounts: MemoizedSelector<AppState, Account[]> = createFeatureSelector("accounts");

export const accountForID = (id: string) =>
    createSelector(accounts, (accounts) => {
        return accounts.find((account) => account.id === id);
    });
