import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Observable } from "rxjs";

export enum ApiKeyMode {
    NEW,
    EDIT,
    ROLL,
    DELETE,
}

interface ViewState {
    mode: ApiKeyMode;
}

interface ViewModel {
    mode: ApiKeyMode;
    title: string;
    showID: boolean;
    showKey: boolean;
    showComments: boolean;
    showRemoveButton: boolean;
    showRollButton: boolean;
    showRollConfirmButton: boolean;
    showRemoveConfirmButton: boolean;
    showCloseButton: boolean;
    showCancelButton: boolean;
    showSaveButton: boolean;
}

@Injectable()
export class ViewStore extends ComponentStore<ViewState> {
    constructor() {
        super({
            mode: ApiKeyMode.NEW,
        });
    }

    readonly setMode = this.updater((state, mode: ApiKeyMode) => ({ ...state, mode }));

    readonly mode$ = this.select((state) => state.mode);
    readonly title$ = this.select(this.mode$, (mode) => {
        switch (mode) {
            case ApiKeyMode.NEW:
                return "API Key Details";
            case ApiKeyMode.EDIT:
                return "Edit API Key";
            case ApiKeyMode.ROLL:
                return "Roll API Key";
            case ApiKeyMode.DELETE:
                return "Delete API Key";
        }
        /* istanbul ignore next */
        return "";
    });
    readonly vm$: Observable<ViewModel> = this.select(this.mode$, this.title$, (mode, title) => {
        return {
            mode,
            title,
            showID: mode === ApiKeyMode.NEW || mode === ApiKeyMode.EDIT,
            showKey: mode === ApiKeyMode.NEW,
            showComments: mode === ApiKeyMode.EDIT,
            showRemoveButton: mode === ApiKeyMode.EDIT,
            showRemoveConfirmButton: mode === ApiKeyMode.DELETE,
            showRollButton: mode === ApiKeyMode.EDIT,
            showRollConfirmButton: mode === ApiKeyMode.ROLL,
            showCloseButton: mode === ApiKeyMode.NEW,
            showCancelButton: mode !== ApiKeyMode.NEW,
            showSaveButton: mode === ApiKeyMode.EDIT,
        };
    });
}
