import { User } from "@hydrantid/acm-client";
import { AppUser } from "./models/app-user";

export const AppUserUtility = {
    canBlockUser(appUser: AppUser | undefined | null, checkUser: User): boolean {
        if (!appUser) {
            return false;
        }
        return (appUser.systemRoles.admin || appUser.accountAnyRoles.admin) && appUser.id !== checkUser.id;
    },
    canResetPasswordForUser(appUser: AppUser | undefined | null, checkUser: User): boolean {
        //also check authUserId.inddexOf("auth0|") === 0
        if (!appUser) {
            return false;
        }
        return (appUser.systemRoles.admin || appUser.accountAnyRoles.admin) && appUser.id !== checkUser.id;
    },
};
