import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";

import { configurationToken, Configuration, setEnvironment, environment } from "./configuration";
import { AuthClientConfig, AuthConfig } from "@auth0/auth0-angular";

// We have to do this in this wacky way because the effects module initializes the service
// which complains about domain and clientID not being set before we even have a chance to set it,
// even though Auth0 has a way to set config outside of forRoot. Oh well, hooray for hacks!
AuthClientConfig.prototype.get = function (): AuthConfig {
    return {
        domain: environment.authDomain,
        clientId: environment.authClientId,
    };
};

const configurationPath = "/configuration.json";
fetch(configurationPath)
    .then((response) => response.json())
    .then((configuration: Configuration) => {
        setEnvironment(configuration);
        if (configuration.production) {
            enableProdMode();
        }

        return platformBrowserDynamic([{ provide: configurationToken, useValue: configuration }]).bootstrapModule(
            AppModule,
        );
    })
    .catch((error) => console.error(error));
