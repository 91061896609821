import { createAction, props } from "@ngrx/store";
import { CertificateAuthority } from "@hydrantid/acm-client";

export const CertificateAuthorityActions = {
    loadCertificateAuthorities: createAction("[Certificate Authorities] Load Certificate Authorities"),
    setCertificateAuthorities: createAction(
        "[Certificate Authorities] Set Certificate Authorities",
        props<{ certificateAuthorities: CertificateAuthority[] }>(),
    ),
};
