import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Log } from "@hydrantid/acm-client";

@Component({
    selector: "app-dialog-details",
    templateUrl: "./dialog-details.component.html",
    styleUrls: ["./dialog-details.component.scss"],
})
export class DialogDetailsComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public log: Log) {}
}
