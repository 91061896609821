import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { EffectsModule } from "@ngrx/effects";
import { AppUserEffects } from "./store/effects";
import * as AppUserSelectors from "./store/selectors";
import { RouterModule, Routes } from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { AuthGuard, AuthModule } from "@auth0/auth0-angular";
import { EulaComponent } from "./components/eula/eula.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Http401Interceptor } from "./interceptors/http/http.interceptor";
export { AppUser, AuthUser } from "./models/app-user";
export { AppUserActions } from "./store/actions";
export { AppUserUtility } from "./utility";
export { AppUserSelectors };

const routes: Routes = [
    {
        path: "agreement",
        component: EulaComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [EulaComponent],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        StoreModule.forFeature("appUser", featureReducer),
        EffectsModule.forFeature([AppUserEffects]),
        RouterModule.forChild(routes),
        AuthModule.forRoot({
            //these values don't matter, they are overridden in the function in main.ts
            domain: "",
            clientId: "",
        }),
    ],
    exports: [EulaComponent, RouterModule],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: Http401Interceptor, multi: true }],
})
export class AppUserModule {}
