import { ActionReducer } from "@ngrx/store/src/models";
import { Action, createReducer, on } from "@ngrx/store";
import { AccountActions } from "./actions";
import { Account } from "@hydrantid/acm-client";

export const initialState: Account[] = [];

const _featureReducer: ActionReducer<Account[]> = createReducer<Account[]>(
    initialState,
    on(AccountActions.setAccounts, (state, { accounts }) => {
        return accounts.slice().sort((a, b) => a.name.localeCompare(b.name));
    }),
    on(AccountActions.updateAccountDeletedAt, (state, { account }) => {
        let updatedAccount: Account | undefined = state.find((filterAccount) => filterAccount.id === account.id);
        if (!updatedAccount) {
            //nothing changed, just return the state
            return state;
        }
        const otherAccounts: Account[] = state.filter((filterAccount) => filterAccount.id !== account.id);
        updatedAccount = { ...updatedAccount, deletedAt: account.deletedAt };
        return [...otherAccounts, updatedAccount].sort((a, b) => a.name.localeCompare(b.name));
    }),
    on(AccountActions.updateAccountOrganization, (state, { id, organization }) => {
        let updatedAccount: Account | undefined = state.find((filterAccount) => filterAccount.id === id);
        if (!updatedAccount) {
            //nothing changed, just return the state
            return state;
        }
        const otherAccounts: Account[] = state.filter((filterAccount) => filterAccount.id !== id);

        updatedAccount = {
            ...updatedAccount,
            Organizations: updatedAccount.Organizations.filter((org) => org.id !== organization.id)
                .concat(organization)
                .sort((a, b) => a.name.localeCompare(b.name)),
        };
        return [...otherAccounts, updatedAccount].sort((a, b) => a.name.localeCompare(b.name));
    }),
    on(AccountActions.updateAccount, (state, { account }) => {
        return state
            .filter((filterAccount) => filterAccount.id !== account.id)
            .concat(account)
            .sort((a, b) => a.name.localeCompare(b.name));
    }),
);

export function featureReducer(state: Account[], action: Action): Account[] {
    return _featureReducer(state, action);
}
