<div class="page-wrapper">
    <div class="page-content">
        <h1>{{ strings.appLongName }}</h1>
        <mat-card appearance="outlined">
            <mat-card-content class="flex flex-col items-center place-content-center">
                <h3>Logging in to {{ connectionInfo.name }}</h3>
            </mat-card-content>
        </mat-card>
    </div>
</div>
