<form [formGroup]="filterForm" (submit)="setFilters.emit()">
    <div class="filter-header flex flex-row items-center place-content-between">
        <h2>{{ title }}</h2>
        <button class="close-drawer" mat-icon-button (click)="sideNav?.toggle()">
            <fa-icon [icon]="icons.times"></fa-icon>
        </button>
    </div>
    <div class="flex flex-col gap-4">
        <ng-content></ng-content>
    </div>
    <div class="layout-padding text-center">
        <button mat-flat-button color="primary">Filter Results</button>
    </div>
</form>
