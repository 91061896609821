import { Action, createReducer, on } from "@ngrx/store";
import { LoadingStatusActions } from "./actions";
import { ActionReducer } from "@ngrx/store/src/models";

const _featureReducer: ActionReducer<number> = createReducer<number>(
    0,
    on(LoadingStatusActions.start, (state) => state + 1),
    on(LoadingStatusActions.end, (state) => state - 1),
);

export function featureReducer(state: number, action: Action): number {
    return _featureReducer(state, action);
}
