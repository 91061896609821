import { createAction, props } from "@ngrx/store";
import { Account, Organization } from "@hydrantid/acm-client";

export const AccountActions = {
    loadAccounts: createAction("[Accounts] Load Accounts"),
    setAccounts: createAction("[Accounts] Set Accounts", props<{ accounts: Account[] }>()),
    disableAccount: createAction("[Accounts] Disable Account", props<{ account: Account }>()),
    enableAccount: createAction("[Accounts] Enable Account", props<{ account: Account }>()),
    updateAccountDeletedAt: createAction("[Accounts] Update Account Deleted At", props<{ account: Account }>()),
    updateAccountOrganization: createAction(
        "[Accounts] organization Data",
        props<{ id: string; organization: Organization }>(),
    ),
    updateAccount: createAction("[Accounts] Update Account", props<{ account: Account }>()),
};
