import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Account, Organization, RolesService, User } from "@hydrantid/acm-client";
import { AccountFilterStore } from "../../../../shared-models/account-filter-state";
import { combineLatest, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MessageService } from "../../../../services/message/message.service";

@Component({
    selector: "app-dialog-add-permission",
    templateUrl: "./dialog-add-permission.component.html",
    styleUrls: ["./dialog-add-permission.component.scss"],
    providers: [AccountFilterStore],
})
export class DialogAddPermissionComponent {
    form = new FormGroup({
        account: new FormControl<Account | undefined>(undefined, { nonNullable: true }),
        organization: new FormControl<Organization | undefined>(undefined, { nonNullable: true }),
        role: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
    });

    availableRoles$: Observable<string[]> = combineLatest([
        this.accountFilterState.selectedAccount$,
        this.accountFilterState.selectedOrganization$,
    ]).pipe(
        switchMap(([selectedAccount, selectedOrganization]) => {
            if (selectedAccount?.id) {
                if (selectedOrganization?.id) {
                    return this.rolesService.rolesListAccountIdOrganizationIdGet(
                        selectedAccount.id,
                        selectedOrganization.id,
                    );
                }
                return this.rolesService.rolesListAccountIdGet(selectedAccount.id);
            }
            return this.rolesService.rolesListGet();
        }),
    );

    constructor(
        @Inject(MAT_DIALOG_DATA) public user: User,
        private dialogRef: MatDialogRef<DialogAddPermissionComponent>,
        public accountFilterState: AccountFilterStore,
        private rolesService: RolesService,
        private messageService: MessageService,
    ) {
        this.accountFilterState.bindSelectionControls(
            this.form.controls.account as FormControl,
            this.form.controls.organization as FormControl,
        );
    }

    savePermission(): void {
        this.rolesService
            .rolesUserIdPut(this.user.id, {
                accountId: this.form.controls.account.value?.id,
                organizationId: this.form.controls.organization.value?.id,
                role: this.form.controls.role.value,
            })
            .subscribe(
                (result) => {
                    if (this.messageService.showResult(result)) {
                        this.dialogRef.close(result.roles);
                    }
                },
                (error) => this.messageService.showError(error.error),
            );
    }
}
