import { NgModule } from "@angular/core";
import { FormatStatusPipe } from "./format-status/format-status.pipe";
import { FormatUserEmailPipe } from "./format-user-email/format-user-email.pipe";
import { FormatValidityPipe } from "./format-validity/format-validity.pipe";
import { SortBlackoutDatesPipe } from "./sort-blackout-dates/sort-blackout-dates.pipe";
import { FormatBlackoutDatesPipe } from "./format-blackout-dates/format-blackout-dates.pipe";
import { FormatCronSchedulePipe } from "./format-cron-schedule/format-cron-schedule.pipe";

@NgModule({
    imports: [],
    declarations: [
        FormatStatusPipe,
        FormatUserEmailPipe,
        FormatValidityPipe,
        SortBlackoutDatesPipe,
        FormatBlackoutDatesPipe,
        FormatCronSchedulePipe,
    ],
    exports: [
        FormatStatusPipe,
        FormatUserEmailPipe,
        FormatValidityPipe,
        SortBlackoutDatesPipe,
        FormatBlackoutDatesPipe,
        FormatCronSchedulePipe,
    ],
})
export class SharedPipesModule {}
