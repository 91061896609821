import { ConfirmDialogComponent } from "./components/confirm-dialog.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
export { DialogsService } from "./services/dialogs.service";
export { DialogData } from "./models/dialog-data";

@NgModule({
    declarations: [ConfirmDialogComponent],
    imports: [CommonModule, MatDialogModule, MatButtonModule],
    exports: [ConfirmDialogComponent],
})
export class DialogsModule {}
