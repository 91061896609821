import { Inject, Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { LoadingStatusActions } from "../store/actions";
import { SHOW_LOADING_FOR_GET } from "../tokens";

@Injectable({
    providedIn: "root",
})
export class LoaderInterceptorService implements HttpInterceptor {
    constructor(
        @Inject(SHOW_LOADING_FOR_GET) private showLoadingForGet: boolean | undefined,
        private globalStore: Store<{ loadingStatus: number }>,
    ) {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method !== "GET" || this.showLoadingForGet) {
            this.globalStore.dispatch(LoadingStatusActions.start());
        }
        return next.handle(req).pipe(
            finalize(() => {
                if (req.method !== "GET" || this.showLoadingForGet) {
                    this.globalStore.dispatch(LoadingStatusActions.end());
                }
            }),
        );
    }
}
