import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./modules/shared/app-user/guards/auth.guard";
import { LogoutComponent } from "./components/logout/logout.component";
import { LoginComponent } from "./components/login/login.component";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard",
    },
    {
        path: "dashboard",
        loadChildren: () => import("./modules/dashboard").then((m) => m.AppDashboardModule),
        canActivate: [AuthGuard],
        data: {
            notAllowedRedirectTo: "domains",
        },
    },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "login/:connection",
        component: LoginComponent,
    },
    {
        path: "help",
        loadChildren: () => import("./modules/help").then((m) => m.AppHelpModule),
        canActivate: [AuthGuard],
    },
    {
        path: "user",
        loadChildren: () => import("./modules/shared/app-user").then((m) => m.AppUserModule),
        canActivate: [AuthGuard],
    },
    {
        path: "certificates",
        loadChildren: () => import("./modules/certificates").then((m) => m.AppCertificatesModule),
        canActivate: [AuthGuard],
    },
    {
        path: "logs",
        loadChildren: () => import("./modules/logs").then((m) => m.AppLogsModule),
        canActivate: [AuthGuard],
    },
    {
        path: "accounts",
        loadChildren: () => import("./modules/accounts").then((m) => m.AppAccountsModule),
        canActivate: [AuthGuard],
    },
    {
        path: "users",
        loadChildren: () => import("./modules/users").then((m) => m.AppUsersModule),
        canActivate: [AuthGuard],
    },
    {
        path: "cas",
        loadChildren: () => import("./modules/certificate-authorities").then((m) => m.AppCertificateAuthoritiesModule),
        canActivate: [AuthGuard],
    },
    {
        path: "certificates/request",
        loadChildren: () => import("./modules/certificate-request").then((m) => m.AppCertificateRequestModule),
        canActivate: [AuthGuard],
    },
    {
        path: "certificates/upload",
        loadChildren: () => import("./modules/certificate-upload").then((m) => m.AppCertificateUploadModule),
        canActivate: [AuthGuard],
    },
    {
        path: "requests",
        loadChildren: () => import("./modules/requests-queue").then((m) => m.AppRequestsQueueModule),
        canActivate: [AuthGuard],
    },
    {
        path: "profile",
        loadChildren: () => import("./modules/profiles").then((m) => m.AppProfilesModule),
        canActivate: [AuthGuard],
    },
    {
        path: "domains",
        loadChildren: () => import("./modules/domains").then((m) => m.DomainsModule),
        canActivate: [AuthGuard],
    },
    {
        path: "logout",
        component: LogoutComponent,
    },
    {
        path: "admin/accounts",
        loadChildren: () => import("./modules/admin/accounts").then((m) => m.AppAccountAdminModule),
        canActivate: [AuthGuard],
    },
    {
        path: "tlsdiscovery",
        loadChildren: () => import("./modules/tls-discovery").then((m) => m.AppTlsDiscoveryModule),
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
