import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { Account, Organization, Policy } from "@hydrantid/acm-client";
import { Observable } from "rxjs";
import { PolicySelectors } from "../modules/shared/policies";
import { AccountSelectors } from "../modules/shared/accounts";
import { FormControl } from "@angular/forms";
import { withLatestFrom } from "rxjs/operators";
import { AppUser, AppUserSelectors } from "../modules/shared/app-user";
import { PolicyState } from "../modules/shared/policies/state";

interface AccountFilterState {
    selectedAccount: Account | null;
    selectedOrganization: Organization | null;
    selectedPolicy: Policy | null;
    typeaheadAccount: string;
    typeaheadOrganization: string;
    typeaheadPolicy: string;
    showOnlyImportedPolicies: boolean;
}

export interface BindControlConfig {
    delayDefault?: boolean;
    autoSelectOrg?: boolean;
    autoSelectPolicy?: boolean;
    autoSelectFirstPolicy?: boolean;
    showOnlyImportedPolicies?: boolean;
    allowDisabledOrganizationControl?: boolean;
    allowDisabledPolicyControl?: boolean;
}

@Injectable()
export class AccountFilterStore extends ComponentStore<AccountFilterState> {
    accountControl: FormControl | undefined;
    organizationControl: FormControl | undefined;
    policyControl: FormControl | undefined;
    config: BindControlConfig = { allowDisabledPolicyControl: true, allowDisabledOrganizationControl: true };

    constructor(private globalStore: Store<{ appUser: AppUser; accounts: Account[]; policies: PolicyState }>) {
        super({
            selectedAccount: null,
            selectedOrganization: null,
            selectedPolicy: null,
            typeaheadAccount: "",
            typeaheadOrganization: "",
            typeaheadPolicy: "",
            showOnlyImportedPolicies: false,
        });
    }

    readonly setShowOnlyImportedPolicies = this.updater((state, showOnlyImportedPolicies: boolean) => ({
        ...state,
        showOnlyImportedPolicies,
    }));

    readonly showOnlyImportedPolicies$: Observable<boolean> = this.select((state) => state.showOnlyImportedPolicies);

    readonly setSelectedAccount = this.updater((state, selectedAccount: Account | null) => ({
        ...state,
        selectedOrganization: null,
        selectedPolicy: null,
        selectedAccount,
    }));

    readonly selectedAccount$: Observable<Account | null> = this.select((state) => state.selectedAccount);

    readonly setSelectedOrganization = this.updater((state, selectedOrganization: Organization | null) => ({
        ...state,
        selectedOrganization,
        selectedPolicy: null,
    }));

    readonly selectedOrganization$: Observable<Organization | null> = this.select(
        (state) => state.selectedOrganization,
    );

    readonly availableAccounts$: Observable<Account[]> = this.globalStore.select(AccountSelectors.accounts);

    getAvailableOrganizationsForAccount(account: Account | null, appUser: AppUser | undefined | null): Organization[] {
        if (!appUser || !account?.Organizations) {
            return [];
        }
        if (
            appUser.systemRoles.admin ||
            appUser.systemRoles.auditor ||
            appUser.accountAnyRoles.admin ||
            appUser.accountAnyRoles.auditor
        ) {
            return account.Organizations;
        }
        return account.Organizations.filter(
            (organization) => organization.id && organization.id in appUser.organizationRoles,
        );
    }

    readonly availableOrganizations$: Observable<Organization[]> = this.select(
        this.selectedAccount$,
        this.globalStore.select(AppUserSelectors.user),
        (selectedAccount, appUser) => this.getAvailableOrganizationsForAccount(selectedAccount, appUser),
    );

    getAvailablePoliciesForOrganization(organization: Organization, policies: Policy[]): Policy[] {
        return policies.filter((policy) => policy.organizationId === organization.id);
    }

    readonly availablePolicies$: Observable<Policy[]> = this.select(
        this.selectedOrganization$,
        this.showOnlyImportedPolicies$,
        this.globalStore.select(PolicySelectors.policies),
        this.globalStore.select(PolicySelectors.importedPolicies),
        (organization, showOnlyImportedPolicies, policies, importedPolicies) => {
            if (
                (!showOnlyImportedPolicies && !policies) ||
                (showOnlyImportedPolicies && !importedPolicies) ||
                !organization
            ) {
                return [];
            }
            const availablePolicies = showOnlyImportedPolicies ? importedPolicies : policies;
            return this.getAvailablePoliciesForOrganization(organization, availablePolicies);
        },
    );

    readonly setTypeaheadAccount = this.updater((state, typeaheadAccount: string) => ({
        ...state,
        typeaheadAccount,
    }));
    readonly typeaheadAccount$: Observable<string> = this.select((state) => state.typeaheadAccount);

    readonly setTypeaheadOrganization = this.updater((state, typeaheadOrganization: string) => ({
        ...state,
        typeaheadOrganization,
    }));
    readonly typeaheadOrganization$: Observable<string> = this.select((state) => state.typeaheadOrganization);

    readonly setTypeaheadPolicy = this.updater((state, typeaheadPolicy: string) => ({
        ...state,
        typeaheadPolicy,
    }));
    readonly typeaheadPolicy$: Observable<string> = this.select((state) => state.typeaheadPolicy);

    readonly filteredAccounts$: Observable<Account[]> = this.select(
        this.availableAccounts$,
        this.typeaheadAccount$,
        (accounts, filterValue) => {
            if (!filterValue) {
                return accounts;
            }
            const lowerFilterValue = filterValue.toLowerCase();
            return accounts.filter((account) => account.name.toLocaleLowerCase().indexOf(lowerFilterValue) >= 0);
        },
    );

    readonly activeFilteredAccounts$: Observable<Account[]> = this.select(this.filteredAccounts$, (accounts) => {
        return accounts.filter((account) => !account.deletedAt);
    });

    readonly filteredOrganizations$: Observable<Organization[]> = this.select(
        this.availableOrganizations$,
        this.typeaheadOrganization$,
        (organizations, filterValue) => {
            if (!filterValue) {
                return organizations;
            }
            const lowerFilterValue = filterValue.toLowerCase();
            return organizations.filter(
                (organization) => organization.name.toLocaleLowerCase().indexOf(lowerFilterValue) >= 0,
            );
        },
    );

    readonly filteredPolicies$: Observable<Policy[]> = this.select(
        this.availablePolicies$,
        this.typeaheadPolicy$,
        (policies, filterValue) => {
            if (!filterValue) {
                return policies;
            }
            const lowerFilterValue = filterValue.toLowerCase();
            return policies.filter((policy) => policy.name.toLocaleLowerCase().indexOf(lowerFilterValue) >= 0);
        },
    );

    handleAccountControlValueChange(account: Account | string | null, appUser: AppUser | null | undefined): void {
        if (typeof account === "string") {
            //@ts-ignore
            this.setTypeaheadAccount(account);
            this.setSelectedAccount(null);
        } else {
            this.setSelectedAccount(account ?? null);
            if (this.organizationControl) {
                let availableOrganizations: Organization[] = [];
                if (account && this.config.autoSelectOrg) {
                    availableOrganizations = this.getAvailableOrganizationsForAccount(account, appUser);
                }
                if (availableOrganizations.length === 1) {
                    this.organizationControl.setValue(availableOrganizations[0]);
                    if (this.config.allowDisabledOrganizationControl) {
                        this.organizationControl.disable();
                    }
                } else {
                    this.organizationControl.setValue(null);
                    this.organizationControl.enable();
                }
            }
        }
    }

    handleOrganizationControlValueChange(organization: Organization | string | null, policies: Policy[]): void {
        if (typeof organization === "string") {
            //@ts-ignore`
            this.setTypeaheadOrganization(organization);
            this.setSelectedOrganization(null);
        } else {
            this.setSelectedOrganization(organization ?? null);
            if (this.policyControl) {
                let availablePolicies: Policy[] = [];
                if (organization && (this.config.autoSelectFirstPolicy || this.config.autoSelectPolicy)) {
                    availablePolicies = this.getAvailablePoliciesForOrganization(organization, policies);
                }
                let shouldDisable = false;
                if (
                    (availablePolicies.length === 1 && this.config.autoSelectPolicy) ||
                    this.config.autoSelectFirstPolicy
                ) {
                    this.policyControl.setValue(availablePolicies[0]);
                    shouldDisable = availablePolicies.length === 1;
                } else {
                    this.policyControl.setValue(null);
                }
                if (shouldDisable && this.config.allowDisabledPolicyControl) {
                    this.policyControl.disable();
                } else {
                    this.policyControl.enable();
                }
            }
        }
    }

    handlePolicyControlValueChange(policy: Policy | string | null): void {
        if (typeof policy === "string") {
            //@ts-ignore
            this.setTypeaheadPolicy(policy);
        }
    }

    bindSelectionControls(
        accountControl: FormControl,
        organizationControl?: FormControl,
        policyControl?: FormControl,
        config: BindControlConfig = {},
    ): void {
        this.accountControl = accountControl;
        this.organizationControl = organizationControl;
        this.policyControl = policyControl;
        this.config = config;

        if (this.config.showOnlyImportedPolicies) {
            this.setShowOnlyImportedPolicies(true);
        }

        accountControl.valueChanges
            .pipe(withLatestFrom(this.globalStore.select(AppUserSelectors.user)))
            .subscribe(([account, appUser]) => this.handleAccountControlValueChange(account, appUser));

        if (organizationControl) {
            organizationControl.valueChanges
                .pipe(
                    withLatestFrom(
                        this.globalStore.select(PolicySelectors.policies),
                        this.globalStore.select(PolicySelectors.importedPolicies),
                        this.showOnlyImportedPolicies$,
                    ),
                )
                .subscribe(([organization, policies, importedPolicies, showOnlyImportedPolicies]) => {
                    const policyList = showOnlyImportedPolicies ? importedPolicies : policies;
                    this.handleOrganizationControlValueChange(organization, policyList);
                });
        }

        if (policyControl) {
            policyControl.valueChanges.subscribe((policy) => this.handlePolicyControlValueChange(policy));
        }
        if (!config.delayDefault) {
            this.overrideDefaults();
        }
    }

    overrideDefaults(): void {
        this.availableAccounts$.subscribe((accounts) => {
            if (!this.accountControl || !accounts) {
                return;
            }
            if (accounts.length === 1) {
                this.accountControl.setValue(accounts[0]);
                this.accountControl.disable();
            } else {
                this.accountControl.enable();
            }
        });
    }

    displayName(item: Account | Organization | Policy | null): string {
        if (!item) {
            return "";
        }
        return item.name;
    }
}
