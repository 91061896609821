import { ActionReducer } from "@ngrx/store/src/models";
import { Action, createReducer, on } from "@ngrx/store";
import { CertificateAuthorityActions } from "./actions";
import { CertificateAuthority } from "@hydrantid/acm-client";

export const initialState: CertificateAuthority[] = [];

const _featureReducer: ActionReducer<CertificateAuthority[]> = createReducer<CertificateAuthority[]>(
    initialState,
    on(
        CertificateAuthorityActions.setCertificateAuthorities,
        (state, { certificateAuthorities }) => certificateAuthorities,
    ),
);

export function featureReducer(state: CertificateAuthority[], action: Action): CertificateAuthority[] {
    return _featureReducer(state, action);
}
