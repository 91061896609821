import { Component, Input } from "@angular/core";
import { IconsService } from "../../../../services/icons/icons.service";
import { MessageService } from "../../../../services/message/message.service";

@Component({
    selector: "copy-to-clipboard",
    templateUrl: "./copy-to-clipboard.component.html",
    styleUrls: ["./copy-to-clipboard.component.scss"],
})
export class CopyToClipboardComponent {
    @Input() contentToCopy: string | undefined;
    @Input() toolTipText: string | undefined;
    @Input() successMessage: string | undefined;
    @Input() className: string | undefined;
    copyComplete = false;

    constructor(
        public icons: IconsService,
        public messageService: MessageService,
    ) {}

    confirmCopy(): void {
        this.copyComplete = true;
        if (!this.successMessage) {
            this.successMessage = "Copy Successful";
        }
        this.messageService.showMessage(this.successMessage);

        setTimeout(() => {
            this.copyComplete = false;
        }, 1000);
    }
}
