<button
    class="copy flex-none {{ className }}"
    [ngClass]="{ copied: copyComplete }"
    [matTooltip]="toolTipText"
    mat-flat-button
    [cdkCopyToClipboard]="contentToCopy"
    (click)="confirmCopy()"
>
    <fa-icon [icon]="icons.copy" *ngIf="!copyComplete"></fa-icon>
    <fa-icon [icon]="icons.solidCheck" *ngIf="copyComplete"></fa-icon>
</button>
