import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppUser, AppUserActions } from "../../modules/shared/app-user";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../configuration";
import { Strings } from "../../../strings/strings";
import { AccountConfigurationAuth0Minimal } from "@hydrantid/acm-client";
import { CachedDataService } from "../../services/cached-data/cached-data.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
    strings = Strings;
    connectionInfo: AccountConfigurationAuth0Minimal = environment.authConnectionDefault;

    constructor(
        private activatedRoute: ActivatedRoute,
        private globalStore: Store<{ appUser: AppUser }>,
        private cachedDataService: CachedDataService,
    ) {
        this.activatedRoute.params.subscribe((params) => {
            this.cachedDataService.getConnectionInfoForSlug(params.connection).subscribe({
                next: (connection) => {
                    this.connectionInfo = connection;
                    this.globalStore.dispatch(AppUserActions.login({ connection: this.connectionInfo.connection }));
                },
            });
        });
    }
}
