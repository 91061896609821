import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FiltersComponent } from "./components/filters/filters.component";
import { FilterValuesComponent } from "./components/filter-values/filter-values.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LetDirective } from "@ngrx/component";
import { MaterialModule } from "../../../material.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    declarations: [FiltersComponent, FilterValuesComponent],
    imports: [CommonModule, ReactiveFormsModule, LetDirective, MaterialModule, FormsModule, FontAwesomeModule],
    exports: [FiltersComponent, FilterValuesComponent],
})
export class FiltersModule {}
