import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { EnvironmentStatus } from "../../models/environment-status";
import { Observable } from "rxjs";
import * as EnvironmentStatusSelectors from "../../store/selectors";

@Component({
    selector: "environment-status",
    templateUrl: "./environment-status.component.html",
    styleUrls: ["./environment-status.component.scss"],
})
export class EnvironmentStatusComponent {
    environmentStatus$: Observable<EnvironmentStatus | null> = this.globalStore.select(
        EnvironmentStatusSelectors.status,
    );

    constructor(private globalStore: Store<{ environmentStatus: EnvironmentStatus }>) {}
}
