<div class="page-wrapper">
    <div class="page-header flex-row items-center place-content-between">
        <h3>Terms of Use</h3>
        <div class="flex flex-row items-center place-content-end gap-4">
            <div>You must accept these terms of use to continue</div>
            <button mat-flat-button color="primary" (click)="accept()">Accept Terms of Use</button>
        </div>
    </div>
    <div class="page-content">
        <mat-card appearance="outlined">
            <h3>Last Modified: xxxx</h3>
            <h4><strong>Acceptance of the Terms of Use</strong></h4>
        </mat-card>
    </div>
</div>
