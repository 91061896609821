import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { EffectsModule } from "@ngrx/effects";
import { UserEffects } from "./store/effects";

export * as UsersSelectors from "./store/selectors";
export { UsersActions } from "./store/actions";
import { CommonModule } from "@angular/common";
import { LetDirective } from "@ngrx/component";
export { AvailableUsers } from "./state";

@NgModule({
    imports: [
        CommonModule,
        LetDirective,
        StoreModule.forFeature("availableUsers", featureReducer),
        EffectsModule.forFeature([UserEffects]),
    ],
})
export class UsersModule {}
