import { Injectable } from "@angular/core";
import { User, UsersService } from "@hydrantid/acm-client";
import { DialogsService } from "../../modules/shared/dialogs";
import { MessageService } from "../message/message.service";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class UserManagementService {
    constructor(
        private dialogService: DialogsService,
        private usersService: UsersService,
        private messageService: MessageService,
    ) {}

    resetPassword(user: User | undefined): void {
        if (user) {
            this.dialogService
                .confirm(`Are you sure you want to reset the password of ${user.email}?`, "Reset Password", "Yes", "No")
                .subscribe((dialogResult) => {
                    if (dialogResult) {
                        this.usersService
                            .usersResetPasswordPost({ userId: user.id, email: user.email })
                            .subscribe((result) => {
                                this.messageService.showResult(
                                    result,
                                    `An email has been sent to ${user.email} with password reset instructions.`,
                                );
                            });
                    }
                });
        }
    }

    setBlocked(user: User | undefined, blocked: boolean): Observable<boolean> {
        const response: Subject<boolean> = new Subject<boolean>();
        if (user) {
            const verb = blocked ? "Block" : "Unblock";
            this.dialogService
                .confirm(`Are you sure you want to ${verb.toLowerCase()} ${user.email}?`, `${verb} User`, "Yes", "No")
                .subscribe((dialogResult) => {
                    if (dialogResult) {
                        this.usersService
                            .usersBlockPost({
                                id: user.id,
                                email: user.email,
                                block: blocked,
                            })
                            .subscribe((result) => {
                                response.next(this.messageService.showResult(result));
                            });
                    }
                });
        }
        return response.asObservable();
    }
}
