import { Action, createReducer, on } from "@ngrx/store";
import { EnvironmentStatusActions } from "./actions";
import { EnvironmentStatus } from "../models/environment-status";
import { ActionReducer } from "@ngrx/store/src/models";

export const initialState: EnvironmentStatus | null = null;

const _featureReducer: ActionReducer<EnvironmentStatus | null> = createReducer<EnvironmentStatus | null>(
    initialState,
    on(EnvironmentStatusActions.set, (state, { status }) => status),
);

export function featureReducer(state: EnvironmentStatus | null, action: Action): EnvironmentStatus | null {
    return _featureReducer(state, action);
}
