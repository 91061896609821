import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { ResultsSuccessResponse, ResultError } from "@hydrantid/acm-client";

@Injectable({
    providedIn: "root",
})
export class MessageService {
    constructor(private snackBar: MatSnackBar) {}

    public showMessage(
        message: string,
        options?: { action?: string; autoclose?: boolean; params?: MatSnackBarConfig },
    ): void {
        if (!options) {
            options = {};
        }
        if (!options.params) {
            options.params = {};
        }
        options.params.horizontalPosition = "end";
        options.params.verticalPosition = "top";
        if (options.autoclose !== false) {
            options.params.duration = 3000;
        }
        this.snackBar.open(message, options.action ?? "Close", options.params);
    }

    public showResult(result: ResultsSuccessResponse, successMessage?: string): boolean {
        if (!successMessage) {
            successMessage = result.message;
            if (!successMessage) {
                return true;
            }
        }
        this.showMessage(successMessage);
        return true;
    }

    public showError(error: ResultError): void {
        this.showMessage(error.message || "Unknown Error", { params: { panelClass: "error" }, autoclose: false });
    }
}
