import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../material.module";
import { LetDirective } from "@ngrx/component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { CopyToClipboardComponent } from "./copy-to-clipboard.component";
import { ClipboardModule } from "@angular/cdk/clipboard";

@NgModule({
    declarations: [CopyToClipboardComponent],
    imports: [CommonModule, LetDirective, MaterialModule, FontAwesomeModule, ClipboardModule],
    exports: [CopyToClipboardComponent],
})
export class AppCopyToClipboardModule {}
