import { Action, createReducer, on } from "@ngrx/store";
import { AppUserActions } from "./actions";
import { ActionReducer } from "@ngrx/store/src/models";
import { AppUser } from "../models/app-user";

export const initialState: AppUser | null | undefined = undefined;

const _featureReducer: ActionReducer<AppUser | null | undefined> = createReducer<AppUser | null | undefined>(
    initialState,
    on(AppUserActions.setUser, (state, { appUser }) => appUser),
    on(AppUserActions.acceptedEula, (state, { acceptedEula }) => {
        if (state === null || state === undefined) {
            return state;
        }
        return {
            ...state,
            acceptedEula,
        };
    }),
);

export function featureReducer(state: AppUser | null | undefined, action: Action): AppUser | null | undefined {
    return _featureReducer(state, action);
}
