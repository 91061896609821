import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { take } from "rxjs/operators";
import { Account, Log, LogsService, Organization } from "@hydrantid/acm-client";
import { DialogDetailsComponent } from "../dialog-details/dialog-details.component";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, FormGroup } from "@angular/forms";
import { IFormGroup } from "@rxweb/types";
import { MatDrawer } from "@angular/material/sidenav";
import { LogFilters, LogViewStore } from "./log-view-state";
import { FilterValue } from "../../../filters/components/filter-values/filter-values.component";
import { IconsService } from "../../../../../services/icons/icons.service";
import { DownloaderService } from "../../../downloader";
import { FilterStorageService } from "../../../../../services/filter-storage/filter-storage.service";
import { DialogsService } from "../../../dialogs";
import { MatSort } from "@angular/material/sort";
import { environment } from "../../../../../../configuration";

@Component({
    selector: "app-logs-view-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
    providers: [LogViewStore],
})
export class LogsListComponent implements AfterViewInit {
    @Input() filterDrawer: MatDrawer | undefined = undefined;
    @Input() showDownload = false;
    @Input() storageKey: string | undefined;
    @Input() displayedColumns: string[] = ["type", "email", "description", "createdAt"];
    @Input() showPager = true;
    @Input() deferredLoad = false;
    @Input() requireExplicitLoad = false;
    @Output() setFilterForm = new EventEmitter<IFormGroup<LogFilters>>();

    filterForm = new FormGroup({
        user_id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        type: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        description: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        email: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        start_date: new FormControl<Date | undefined>(undefined, { nonNullable: true }),
        end_date: new FormControl<Date | undefined>(undefined, { nonNullable: true }),
        certificate_id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
        account_id: new FormControl<Account | undefined>(undefined, { nonNullable: true }),
        organization_id: new FormControl<Organization | undefined>(undefined, { nonNullable: true }),
        cert_request_id: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    });

    currentFilters: FilterValue[] = [];

    constructor(
        public logState: LogViewStore,
        public icons: IconsService,
        public datePipe: DatePipe,
        private downloadService: DownloaderService,
        private logsService: LogsService,
        private dialogsService: DialogsService,
        private filterStorageService: FilterStorageService,
        private dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
    ) {}

    loadStoredFilters(): void {
        if (this.storageKey) {
            const savedFilterValues: LogFilters = this.filterStorageService.getFilter(this.storageKey) as LogFilters;
            this.filterForm.patchValue(savedFilterValues);
            this.setFilters(false);
        }
    }

    setFilters(resetPageIndex = true): void {
        const displayFilters: FilterValue[] = [];
        const values = this.filterForm.getRawValue();
        if (this.storageKey) {
            this.filterStorageService.setFilter(this.storageKey, values);
        }

        if (values.type) {
            displayFilters.push({
                value: values.type,
                control: this.filterForm.controls.type as FormControl,
                label: "Event",
            });
        }
        if (values.description) {
            displayFilters.push({
                value: values.description,
                control: this.filterForm.controls.description as FormControl,
                label: "Description",
            });
        }
        if (values.email) {
            displayFilters.push({
                value: values.email,
                control: this.filterForm.controls.email as FormControl,
                label: "Email",
            });
        }
        if (values.start_date) {
            displayFilters.push({
                value: this.datePipe.transform(values.start_date, "MM/dd/yyyy") as string,
                control: this.filterForm.controls.start_date as FormControl,
                label: "Created After",
            });
        }
        if (values.end_date) {
            displayFilters.push({
                value: this.datePipe.transform(values.end_date, "MM/dd/yyyy") as string,
                control: this.filterForm.controls.end_date as FormControl,
                label: "Created Before",
            });
        }
        if (values.account_id) {
            displayFilters.push({
                value: values.account_id.name ?? "",
                control: this.filterForm.controls.account_id as FormControl,
                label: "Account",
            });
        }
        if (values.organization_id) {
            displayFilters.push({
                value: values.organization_id.name ?? "",
                control: this.filterForm.controls.organization_id as FormControl,
                label: "Organization",
            });
        }
        this.currentFilters = displayFilters;
        this.logState.setFilters(values, resetPageIndex);
        if (this.filterDrawer?.opened && this.requireExplicitLoad) {
            this.loadLogs();
        }
        this.filterDrawer?.close();
    }

    getLimitedItemCount(itemCount: number): number {
        if (itemCount > environment.maxLogDownload) {
            itemCount = environment.maxLogDownload;
        }
        return itemCount;
    }

    downloadFiltered(): void {
        this.logState.vm$.pipe(take(1)).subscribe((currentState) => {
            const itemCount = this.getLimitedItemCount(currentState.itemCount);
            const plural = itemCount != 1 ? "s" : "";
            this.dialogsService
                .confirm(
                    `The information for all ${itemCount} log${plural} will be download to a csv file. If you want to change which records are downloaded, close this window and change your filter.`,
                    `Download Log Information: ${itemCount} record${plural}`,
                    "Download",
                    "Close",
                    600,
                )
                .subscribe((result) => {
                    if (result) {
                        this.downloadService.downloadObserver(
                            this.logsService.logsDownloadPost(
                                LogViewStore.payloadFromFilters(this.filterForm.getRawValue()),
                                "response",
                                undefined,
                                {
                                    httpHeaderAccept: "text/csv",
                                },
                            ),
                            "logs_download.csv",
                        );
                    }
                });
        });
    }

    loadLogs(): void {
        this.logState.explicitLoad = this.requireExplicitLoad = false;
        this.logState.loadItems();
    }

    viewLog(log: Log): void {
        this.dialog.open(DialogDetailsComponent, {
            width: "600px",
            data: log,
        });
    }

    @ViewChild(MatSort) sort: MatSort | undefined;

    ngAfterViewInit(): void {
        this.logState.explicitLoad = this.requireExplicitLoad;
        this.loadStoredFilters();
        this.setFilterForm.emit(this.filterForm);
        if (!this.deferredLoad && !this.requireExplicitLoad) {
            this.logState.loadItems();
        }
        if (this.sort) {
            this.logState.initSort(this.sort);
            this.changeDetectorRef.detectChanges();
        }
    }
}
