<div class="table-content-header flex flex-col sm:flex-row gap-4 items-center place-content-between">
    <div class="flex-auto">
        <div class="filter-values flex flex-wrap">
            <div class="filter-chip flex flex-row items-center place-content-between" *ngFor="let filter of values">
                <div class="filter-data flex-col flex">
                    <label>{{ filter.label }}</label>
                    <div>{{ filter.value }}</div>
                </div>
                <button mat-button color="primary" (click)="clearFilterValue(filter)">
                    <fa-icon [icon]="icons.times"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="grow-0 flex flex-row items-center place-content-between">
        <ng-content></ng-content>
        <button mat-button color="primary" (click)="sideNav?.toggle()">Filter Results</button>
    </div>
</div>
