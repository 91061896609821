<h2 mat-dialog-title>Update Password</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <div class="flex flex-col">
            <div class="form-field">
                <mat-form-field>
                    <mat-label>Account Email</mat-label>
                    <input matInput autocomplete="username" [formControl]="form.controls.email" />
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field>
                    <mat-label>Old Password</mat-label>
                    <input
                        type="password"
                        autocomplete="current-password"
                        matInput
                        [formControl]="form.controls.currentPassword"
                    />
                    <mat-error *ngIf="form.controls.currentPassword.hasError('required')" class="error-indicator"
                        >The old password is required</mat-error
                    >
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field>
                    <mat-label>New Password</mat-label>
                    <input
                        type="password"
                        autocomplete="new-password"
                        matInput
                        [formControl]="form.controls.newPassword"
                    />
                    <mat-error *ngIf="form.controls.newPassword.hasError('required')" class="error-indicator"
                        >The new password is required</mat-error
                    >
                </mat-form-field>
            </div>
            <div class="form-field">
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input
                        type="password"
                        autocomplete="confirm-password"
                        matInput
                        [formControl]="form.controls.confirmPassword"
                    />
                    <mat-error *ngIf="form.controls.confirmPassword.hasError('required')" class="error-indicator"
                        >The confirmed password is required</mat-error
                    >
                    <mat-error
                        *ngIf="
                            !form.controls.confirmPassword.hasError('required') &&
                            form.controls.confirmPassword.hasError('no_match')
                        "
                        class="error-indicator"
                        >The new and confirmed password need to match</mat-error
                    >
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button color="primary" (click)="savePassword()" [disabled]="form.invalid">Reset Password</button>
</mat-dialog-actions>
