import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { CertificateAuthorityActions } from "./actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { CAService } from "@hydrantid/acm-client";
import { AppUser, AppUserSelectors } from "../../app-user";

@Injectable()
export class CertificateAuthorityEffects {
    loadCertificateAuthorities$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CertificateAuthorityActions.loadCertificateAuthorities),
            mergeMap(() => {
                return this.caService.casGet().pipe(
                    map((certificateAuthorities) => {
                        return CertificateAuthorityActions.setCertificateAuthorities({ certificateAuthorities });
                    }),
                    catchError(() => {
                        return of({ type: "error" });
                    }),
                );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private caService: CAService,
        private globalStore: Store<{ appUser: AppUser }>,
    ) {
        this.globalStore.select(AppUserSelectors.pagePermissions).subscribe((permissions) => {
            if (permissions?.cas) {
                setTimeout(() => {
                    globalStore.dispatch(CertificateAuthorityActions.loadCertificateAuthorities());
                }, 0);
            }
        });
    }
}
