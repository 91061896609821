<h2 mat-dialog-title>Log Subscriptions</h2>
<mat-dialog-content>
    <p class="mb-2">Select the log types you would like email notifications for:</p>
    <mat-list role="list">
        <mat-list-item *ngFor="let option of options">
            <mat-checkbox color="primary" [formControl]="option.control">{{ option.name }}</mat-checkbox>
        </mat-list-item>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
    <button mat-flat-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
