import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { User } from "@hydrantid/acm-client";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-dialog-manage",
    templateUrl: "./dialog-manage.component.html",
    styleUrls: ["./dialog-manage.component.scss"],
})
export class DialogManageComponent {
    form = new FormGroup({
        firstName: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
        lastName: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
    });
    constructor(@Inject(MAT_DIALOG_DATA) user: User) {
        this.form.patchValue(user);
    }
}
