import { PagingState, SortingState } from "./paging";
import { SortDirectionEnum } from "@hydrantid/acm-client";
import { ComponentStore } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { AppUser, AppUserSelectors } from "../modules/shared/app-user";
import { Observable } from "rxjs";
import { take, tap, withLatestFrom } from "rxjs/operators";
import { PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { FilterStorageService, SavedPagingInformation } from "../services/filter-storage/filter-storage.service";

export interface TableViewState<T, U> {
    filters: U;
    sorting: SortingState;
    paging: PagingState;
    items: T[];
}

export interface TableViewModel<T> {
    pageIndex: number;
    pageSize: number;
    itemCount: number;
    sortType: string;
    sortDirection: SortDirectionEnum;
    items: T[];
}

export interface BaseFilters {
    [key: string]: unknown;
}

export abstract class TableViewStore<T, U extends BaseFilters, V> extends ComponentStore<TableViewState<T, U>> {
    deferredLoad = this.shouldDeferLoad();
    explicitLoad = false;
    initialized = false;

    constructor(
        protected service: V,
        protected globalStore: Store<{ appUser: AppUser }>,
        protected filterStorageService: FilterStorageService,
    ) {
        super({
            paging: {
                pageIndex: 0,
                pageSize: 10,
                itemCount: 0,
            },
            sorting: {
                sortType: "",
                sortDirection: SortDirectionEnum.DESC,
            },
            filters: {} as U,
            items: [],
        });
        this.setDefaultSort();
        this.loadSavedPagingAndSort();
        this.pageValuesChanged$.subscribe(() => {
            //no-op yet
        });
        this.initialized = true;
    }

    shouldDeferLoad(): boolean {
        return false;
    }

    loadSavedPagingAndSort(): void {
        const paging: undefined | SavedPagingInformation = this.filterStorageService.getPaging(this.pagingKey);
        if (paging) {
            this.setPageIndex(paging.i);
            this.setPageSize(paging.s);
            this.setSort({ sortType: paging.sk, sortDirection: paging.sd });
        }
    }

    savePagingAndSort(data: SavedPagingInformation): void {
        if (!this.initialized) {
            return;
        }
        this.filterStorageService.setPaging(this.pagingKey, data);
    }

    get pagingKey(): undefined | string {
        return undefined;
    }

    readonly pageIndex$ = this.select(({ paging }) => paging.pageIndex);
    readonly pageSize$ = this.select(({ paging }) => paging.pageSize);
    readonly items$ = this.select(({ items }) => items);
    readonly sorting$ = this.select(({ sorting }) => sorting);
    readonly filters$ = this.select(({ filters }) => filters);

    handlePossibleDeferredLoad(): void {
        if (this.explicitLoad) {
            return;
        }
        if (this.deferredLoad) {
            this.deferredLoad = false;
            return;
        }
        this.loadItems();
    }

    readonly pageValuesChanged$ = this.select(
        this.pageIndex$,
        this.pageSize$,
        this.sorting$,
        this.filters$,
        (pageIndex, pageSize, sorting) => {
            this.savePagingAndSort({
                i: pageIndex,
                s: pageSize,
                sk: sorting.sortType,
                sd: sorting.sortDirection,
            });
            this.handlePossibleDeferredLoad();
            return {};
        },
    );

    readonly vm$: Observable<TableViewModel<T>> = this.select(
        this.state$,
        this.pageIndex$,
        this.pageSize$,
        this.items$,
        (state, pageIndex, pageSize, items) => ({
            pageIndex,
            pageSize,
            items,
            itemCount: state.paging.itemCount,
            sortType: state.sorting.sortType,
            sortDirection: state.sorting.sortDirection,
        }),
    );

    retryItemLoad(): void {
        this.loadItems();
    }

    abstract setDefaultSort(): void;
    abstract internalLoadItems(state: TableViewState<T, U>): void;

    readonly loadItems = this.effect((trigger$) => {
        return trigger$.pipe(
            withLatestFrom(this.state$, this.globalStore.select(AppUserSelectors.user)),
            tap(([, state, appUser]) => {
                //turn off deferred load since we have explicitly loaded items
                this.deferredLoad = false;
                if (!appUser) {
                    //TODO: there's got to be a better way to do this
                    setTimeout(() => {
                        this.retryItemLoad();
                    }, 500);
                    return;
                }
                this.internalLoadItems(state);
            }),
        );
    });

    readonly setPageIndex = this.updater((state, value: number) => ({
        ...state,
        paging: {
            ...state.paging,
            pageIndex: value,
        },
    }));

    readonly setPageSize = this.updater((state, value: string | number) => ({
        ...state,
        paging: {
            ...state.paging,
            pageSize: Number(value),
        },
    }));

    readonly setSort = this.updater((state, sorting: SortingState) => ({
        ...state,
        sorting,
    }));

    setFilters(filters: U, resetPageIndex = true): void {
        //set any blank strings to undefined
        for (const [k, v] of Object.entries(filters)) {
            if (v === "" || v === undefined) {
                delete filters[k];
            }
        }
        if (resetPageIndex) {
            this.setPageIndex(0);
        }
        this.patchState({ filters });
    }

    readonly setItemCount = this.updater((state, value: number) => ({
        ...state,
        paging: {
            ...state.paging,
            itemCount: value,
        },
    }));

    readonly setItems = this.updater((state, items: T[]) => ({
        ...state,
        items,
    }));

    /* Component helper functions */
    componentPageChanged(event: PageEvent): void {
        this.setPageSize(event.pageSize);
        this.setPageIndex(event.pageIndex);
    }

    componentSortChanged(event: Sort): void {
        this.setSort({
            sortType: event.active,
            sortDirection: event.direction as SortDirectionEnum,
        });
    }

    initSort(sort: MatSort): void {
        this.sorting$.pipe(take(1)).subscribe((value) => {
            //work around bug in sort
            sort.sort({ id: value.sortType, start: value.sortDirection, disableClear: true });
            //and attach the event for future sorting
            sort.sortChange.subscribe((value) => this.componentSortChanged(value));
        });
    }
}
