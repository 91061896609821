<div class="page-wrapper" *ngrxLet="viewStore.viewModel$; let vm">
    <mat-drawer-container hasBackdrop="true" class="h_auto">
        <mat-drawer-content>
            <div class="page-header with-back-button flex-row items-center place-content-between">
                <button class="back-button" mat-flat-button (click)="goBackToUsers()" *ngIf="showBackButton">
                    <fa-icon [icon]="icons.chevronLeft" size="1x"></fa-icon>
                </button>
                <h2 class="flex flex-auto">Profile</h2>
                <div class="flex flex-row items-center place-content-end gap-1">
                    <button
                        mat-button
                        color="primary"
                        *ngIf="vm.pagePermissions.reassignNotificationEmail"
                        (click)="reassignEmails(vm.user)"
                    >
                        Reassign Emails/Certs
                    </button>
                    <button
                        mat-button
                        color="primary"
                        *ngIf="vm.pagePermissions.block"
                        (click)="setBlocked(vm.user, !vm.user?.blocked)"
                    >
                        <span *ngIf="!vm.user?.blocked">Block</span>
                        <span *ngIf="vm.user?.blocked">Unblock</span>
                    </button>
                    <button
                        mat-button
                        color="primary"
                        *ngIf="vm.pagePermissions.resetPassword && vm.user?.authSourceType === 'database'"
                        (click)="resetPassword(vm.user)"
                    >
                        Reset Password
                    </button>
                </div>
            </div>
            <div class="page-content">
                <div class="blocked-message" *ngIf="vm.errorMessage">{{ vm.errorMessage }}</div>
                <mat-card appearance="outlined" class="no-padding" *ngIf="vm.user">
                    <div class="blocked-message" *ngIf="vm.user?.blocked">
                        <strong>{{ vm.user?.email }}</strong> is currently blocked
                    </div>
                    <div class="flex flex-col">
                        <div class="border-bottom flex flex-col" *ngIf="vm.auth0User">
                            <div
                                class="section-header border-bottom profile-name flex flex-col sm:flex-row items-start sm:items-center gap-5 sm:gap-2"
                            >
                                <div class="flex flex-col flex-auto">
                                    <div class="flex flex-row items-center gap-1">
                                        <h2>{{ vm.user.firstName }} {{ vm.user.lastName }}</h2>
                                        <button
                                            mat-icon-button
                                            *ngIf="vm.pagePermissions.editName"
                                            (click)="manageName(vm.user)"
                                        >
                                            <fa-icon [icon]="icons.pencil"></fa-icon>
                                        </button>
                                    </div>
                                    <h4>{{ vm.user.email }}</h4>
                                </div>
                                <div class="stacked-label sm:text-right flex-none">
                                    <label>Login Counts</label>
                                    {{ vm.auth0User.logins_count || 0 }}
                                </div>
                            </div>
                            <div class="profile-details flex flex-col gap-6">
                                <div class="flex flex-col sm:flex-row gap-6">
                                    <div class="stacked-label basis-1/2">
                                        <label>Creation Date</label>
                                        {{ (vm.user.createdAt | date: "medium") || "N/A" }}
                                    </div>
                                    <div class="stacked-label basis-1/2">
                                        <label>Last Updated</label>
                                        {{ (vm.user.updatedAt | date: "medium") || "N/A" }}
                                    </div>
                                </div>
                                <div class="flex flex-col sm:flex-row gap-6">
                                    <div class="stacked-label basis-1/2">
                                        <label>Last Login</label>
                                        {{ (vm.auth0User.last_login | date: "medium") || "N/A" }}
                                    </div>
                                    <div class="stacked-label basis-1/2">
                                        <label>Last IP Address</label>
                                        {{ vm.auth0User.last_ip || "N/A" }}
                                    </div>
                                </div>
                                <div class="flex flex-col sm:flex-row gap-6">
                                    <div class="stacked-label basis-1/2" *ngIf="vm.user.authSourceType === 'database'">
                                        <label>Multifactor Authentication</label>
                                        {{ vm.mfaRequired ? "Required" : vm.mfaEnabled ? "Enabled" : "Not Enabled" }}
                                        <ng-container>
                                            <button
                                                mat-flat-button
                                                (click)="toggleMFA(vm.user)"
                                                *ngIf="vm.pagePermissions.mfaToggle && !vm.mfaRequired"
                                            >
                                                <fa-icon [icon]="icons.pencilSolid"></fa-icon>&nbsp;
                                                <span *ngIf="!vm.mfaEnabled">Enable</span>
                                                <span *ngIf="vm.mfaEnabled">Disable</span>
                                            </button>
                                            <button
                                                mat-flat-button
                                                (click)="resetMFA(vm.user)"
                                                *ngIf="vm.mfaEnabled && vm.pagePermissions.mfaModify"
                                            >
                                                <fa-icon [icon]="icons.pencilSolid"></fa-icon>&nbsp;Reset MFA
                                            </button>
                                        </ng-container>
                                    </div>
                                    <div class="stacked-label basis-1/2" *ngIf="vm.user.authSourceType !== 'database'">
                                        <label>Auth Source</label>
                                        {{ vm.connectionInfo?.name || "Unknown" }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="border-bottom" *ngIf="vm.pagePermissions.rolesView">
                            <div class="section-header pt-3 flex flex-row items-center place-content-between">
                                <h4>Accounts and Roles</h4>
                                <button
                                    mat-button
                                    color="primary"
                                    (click)="addPermission(vm.user)"
                                    *ngIf="vm.pagePermissions.rolesAdd"
                                >
                                    Add Account Role
                                </button>
                            </div>
                            <div class="table-content" *ngrxLet="viewStore.permissions$; let permissions">
                                <table
                                    mat-table
                                    #permissionsTable
                                    #permissionsTableSort="matSort"
                                    [dataSource]="permissionsDataSource"
                                    matSort
                                    matSortDisableClear
                                >
                                    <ng-container matColumnDef="accountName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="accountName">Account</th>
                                        <td mat-cell *matCellDef="let row">{{ row.accountName }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="organizationName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="organizationName">
                                            Organization
                                        </th>
                                        <td mat-cell *matCellDef="let row">{{ row.organizationName }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="roleName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="roleName">Role</th>
                                        <td mat-cell *matCellDef="let row">{{ row.roleName }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="action">
                                        <th mat-header-cell *matHeaderCellDef class="table-cell-align-right"></th>
                                        <td mat-cell *matCellDef="let row" class="table-cell-align-right">
                                            <button
                                                mat-icon-button
                                                matTooltip="Remove role"
                                                (click)="viewStore.removePermission(row)"
                                                *ngIf="
                                                    permissions.length > 1 &&
                                                    (viewStore.canRemoveRole(row.accountId) | async)
                                                "
                                            >
                                                <fa-icon [icon]="icons.trash"></fa-icon>
                                            </button>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="permissionsDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: permissionsDisplayedColumns"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell no-data" colspan="100%">No permissions</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="border-bottom" *ngIf="vm.pagePermissions.apiKeysView">
                            <div class="section-header pt-3 flex flex-row items-center place-content-between">
                                <h4>API Keys</h4>
                                <button
                                    mat-button
                                    color="primary"
                                    (click)="addApiKey(vm.user)"
                                    *ngIf="vm.pagePermissions.apiKeysManage"
                                    [disabled]="!vm.allowMoreCredentials"
                                >
                                    Add API Key
                                </button>
                            </div>
                            <div class="table-content">
                                <table
                                    mat-table
                                    #apiKeysTableSort="matSort"
                                    [dataSource]="apiKeysDataSource"
                                    matSort
                                    matSortDisableClear
                                >
                                    <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
                                        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="createdAt">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdAt">Created</th>
                                        <td mat-cell *matCellDef="let row">{{ row.createdAt | date: "medium" }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="lastUsed">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastUsed">Last Used</th>
                                        <td mat-cell *matCellDef="let row">{{ row.lastUsed | date: "medium" }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="comments">
                                        <th
                                            mat-header-cell
                                            *matHeaderCellDef
                                            mat-sort-header="comments"
                                            class="table-cell-align-right"
                                        >
                                            Comments
                                        </th>
                                        <td mat-cell *matCellDef="let row" class="table-cell-align-right">
                                            {{ row.comments }}
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="keysDisplayedColumns"></tr>
                                    <tr
                                        mat-row
                                        *matRowDef="let row; columns: keysDisplayedColumns"
                                        class="clickable"
                                        (click)="vm.pagePermissions.apiKeysManage ? manageApiKey(vm.user, row) : false"
                                    ></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell no-data" colspan="100%">
                                            No API Keys exist or insufficient rights to view
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="border-bottom" *ngIf="vm.pagePermissions.subscriptionsView">
                            <div class="section-header pt-3 flex flex-row items-center place-content-between">
                                <h4>Log Subscriptions</h4>
                                <button
                                    mat-button
                                    color="primary"
                                    (click)="editSubscriptions(vm.user)"
                                    *ngIf="vm.pagePermissions.subscriptionsManage"
                                >
                                    Edit Subscriptions
                                </button>
                            </div>
                            <div class="table-content">
                                <table mat-table [dataSource]="viewStore.notificationSubscriptions$ | async">
                                    <ng-container matColumnDef="logType">
                                        <th mat-header-cell *matHeaderCellDef>Log Type</th>
                                        <td mat-cell *matCellDef="let row">{{ row }}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="['logType']"></tr>
                                    <tr mat-row *matRowDef="let row; columns: ['logType']"></tr>
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell no-data" colspan="100%">Not subscribed to any log types</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <ng-container *ngIf="vm.user">
                            <div
                                class="p-3 pl-4 section-header clickable flex flex-row items-center place-content-between"
                                (click)="toggleEventsExpanded(vm.user.id)"
                            >
                                <h4>Events</h4>
                                <button mat-flat-button>
                                    <fa-icon [icon]="icons.chevronUp" *ngIf="!eventsExpanded"></fa-icon>
                                    <fa-icon [icon]="icons.chevronDown" *ngIf="eventsExpanded"></fa-icon>
                                </button>
                            </div>
                            <div class="collapsible-section-content" [hidden]="!eventsExpanded">
                                <div class="flex flex-col gap-4">
                                    <div>
                                        <app-filter-values
                                            [filterForm]="filterForm"
                                            [values]="logs?.currentFilters"
                                            (setFilters)="logs?.setFilters()"
                                            [sideNav]="filterDrawer"
                                        ></app-filter-values>
                                    </div>
                                    <app-logs-view-list
                                        #logs
                                        [requireExplicitLoad]="true"
                                        [displayedColumns]="['type', 'description', 'createdAt']"
                                        [filterDrawer]="filterDrawer"
                                        (setFilterForm)="setFilterForm($event)"
                                        [deferredLoad]="true"
                                    ></app-logs-view-list>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </mat-card>
            </div>
        </mat-drawer-content>
        <mat-drawer #filterDrawer mode="over" position="end" class="filter-drawer" autoFocus="false">
            <ng-container *ngIf="filterForm">
                <app-filters
                    title="Logs Filter"
                    [sideNav]="filterDrawer"
                    [filterForm]="filterForm"
                    (setFilters)="logs?.setFilters()"
                >
                    <mat-form-field>
                        <input matInput [formControl]="filterForm.controls.type" placeholder="Event" />
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput [formControl]="filterForm.controls.description" placeholder="Description" />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input
                                matStartDate
                                placeholder="Created After"
                                [formControl]="filterForm.controls.start_date"
                            />
                            <input
                                matEndDate
                                placeholder="Created Before"
                                [formControl]="filterForm.controls.end_date"
                            />
                        </mat-date-range-input>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            [formControl]="filterForm.controls.account_id"
                            placeholder="Select an Account"
                            [matAutocomplete]="account"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #account="matAutocomplete"
                            [displayWith]="accountFilterState.displayName"
                        >
                            <mat-option
                                *ngFor="let account of accountFilterState.activeFilteredAccounts$ | async"
                                [value]="account"
                                >{{ account.name }}</mat-option
                            >
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field *ngIf="accountFilterState.selectedAccount$ | async">
                        <input
                            matInput
                            [formControl]="filterForm.controls.organization_id"
                            placeholder="Select an Organization"
                            [matAutocomplete]="organization"
                        />
                        <mat-autocomplete
                            autoActiveFirstOption
                            #organization="matAutocomplete"
                            [displayWith]="accountFilterState.displayName"
                        >
                            <mat-option
                                *ngFor="let organization of accountFilterState.filteredOrganizations$ | async"
                                [value]="organization"
                                >{{ organization.name }}</mat-option
                            >
                        </mat-autocomplete>
                    </mat-form-field>
                </app-filters>
            </ng-container>
        </mat-drawer>
    </mat-drawer-container>
</div>
