import { createAction, props } from "@ngrx/store";
import { AppUser } from "../models/app-user";

export const AppUserActions = {
    login: createAction("[AppUser] Login", props<{ redirect?: string | undefined; connection?: string | undefined }>()),
    logout: createAction("[AppUser] Logout"),
    setUser: createAction("[AppUser] Set User", props<{ appUser: AppUser | null | undefined }>()),
    acceptEula: createAction("[AppUser] Accept EULA"),
    acceptedEula: createAction("[AppUser] Accepted EULA", props<{ acceptedEula: boolean }>()),
};
