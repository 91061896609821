import { createAction, props } from "@ngrx/store";
import { Policy } from "@hydrantid/acm-client";

export const PolicyActions = {
    loadPolicies: createAction("[Policies] Load Policies"),
    setPolicies: createAction("[Policies] Set Policies", props<{ policies: Policy[] }>()),
    updatePolicies: createAction("[Policies] Update Policies", props<{ policy: Policy }>()),
    loadImportedPolicies: createAction("[Policies] Load Imported Policies"),
    setImportedPolicies: createAction("[Policies] Set Imported Policies", props<{ importedPolicies: Policy[] }>()),
};
