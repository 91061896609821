import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppUser, AppUserActions } from "../../modules/shared/app-user";
import { Strings } from "../../../strings/strings";
import { FilterStorageService } from "../../services/filter-storage/filter-storage.service";
import { RedirectorService } from "../../modules/shared/app-user/services/redirector.service";
@Component({
    selector: "app-logout",
    templateUrl: "./logout.component.html",
    styleUrls: ["./logout.component.scss"],
})
export class LogoutComponent {
    strings = Strings;
    signoutMessage?: string;
    constructor(
        private globalStore: Store<{ appUser: AppUser }>,
        private filterStorageService: FilterStorageService,
        private redirectorService: RedirectorService,
    ) {
        this.filterStorageService.clear();
    }
    ngOnInit() {
        this.signoutMessage = this.redirectorService.errorMsg ?? "You have been logged out";
    }

    login(): void {
        this.globalStore.dispatch(AppUserActions.login({}));
    }
}
