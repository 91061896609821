import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HawkCredential, UsersService } from "@hydrantid/acm-client";
import { ApiKeyMode, ViewStore } from "./view-store";
import { MessageService } from "../../../../services/message/message.service";
import { IconsService } from "../../../../services/icons/icons.service";

export interface DialogApiKeyData {
    userId: string;
    credential: HawkCredential;
    mode: ApiKeyMode;
}
@Component({
    selector: "app-dialog-api-key",
    templateUrl: "./dialog-api-key.component.html",
    styleUrls: ["./dialog-api-key.component.scss"],
    providers: [ViewStore],
})
export class DialogApiKeyComponent {
    ApiKeyModes = ApiKeyMode;

    form = new FormGroup({
        id: new FormControl<string | undefined>({ value: "", disabled: true }, { nonNullable: true }),
        key: new FormControl<string | undefined>({ value: "", disabled: true }, { nonNullable: true }),
        comments: new FormControl<string | undefined>("", { nonNullable: true }),
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogApiKeyData,
        private dialogRef: MatDialogRef<DialogApiKeyComponent>,
        public viewStore: ViewStore,
        public icons: IconsService,
        private usersService: UsersService,
        private messageService: MessageService,
    ) {
        this.form.patchValue(data.credential);
        this.viewStore.setMode(data.mode);
    }

    save(): void {
        this.usersService
            .usersHawkUserIdHawkIdPatch(this.data.userId, this.data.credential.id, {
                comments: this.form.controls.comments.value,
            })
            .subscribe(
                (result) => {
                    this.messageService.showMessage("The API Key was saved");
                    this.data.credential = result;
                    this.dialogRef.close(this.data);
                },
                (error) => this.messageService.showError(error.error),
            );
    }

    roll(): void {
        this.usersService.usersHawkUserIdHawkIdPut(this.data.userId, this.data.credential.id, {}).subscribe(
            (result) => {
                this.messageService.showMessage("The API Key was rolled");
                this.data.mode = ApiKeyMode.ROLL;
                this.data.credential = result;
                this.dialogRef.close(this.data);
            },
            (error) => this.messageService.showError(error.error),
        );
    }

    remove(): void {
        this.usersService.usersHawkUserIdHawkIdDelete(this.data.userId, this.data.credential.id).subscribe(
            (result) => {
                if (result.deleted) {
                    this.messageService.showMessage("The API Key was removed");
                    this.data.mode = ApiKeyMode.DELETE;
                    this.dialogRef.close(this.data);
                }
            },
            (error) => this.messageService.showError(error.error),
        );
    }
}
