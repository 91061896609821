import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AppUserActions } from "./actions";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthUserResponseRoles, Configuration, UserAccountOrganization, UsersService } from "@hydrantid/acm-client";
import { AppState, AuthService } from "@auth0/auth0-angular";
import { Store } from "@ngrx/store";
import { AppUser, AuthUser, DEFAULT_ROLES, DomainValidator } from "../models/app-user";
import { DOCUMENT } from "@angular/common";
import { AUTH_CONFIG, AuthConfig } from "../tokens";
import { RedirectorService } from "../services/redirector.service";
import { PrimaryRole } from "../models/primary-roles";
import { RedirectLoginOptions } from "@auth0/auth0-angular/lib/interfaces";

@Injectable()
export class AppUserEffects {
    login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppUserActions.login),
            map((action) => {
                if (action.redirect) {
                    this.redirectorService.setRedirect(action.redirect);
                } else {
                    this.redirectorService.clearRedirect();
                }
                const params: RedirectLoginOptions<AppState> = {
                    authorizationParams: {
                        redirect_uri: this.document.location.origin,
                        connection: action.connection ?? this.authConfig.connection,
                    },
                };
                this.authService.loginWithRedirect(params);
                return { type: "Unknown" };
            }),
        );
    });

    logout$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppUserActions.logout),
            map(() => {
                this.authService.logout({
                    logoutParams: {
                        returnTo: this.document.location.origin + "/logout",
                    },
                });
                return { type: "Unknown" };
            }),
        );
    });

    acceptEula$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppUserActions.acceptEula),
            map(() => {
                const result = AppUserActions.acceptedEula({ acceptedEula: true });
                this.router.navigate(["/"]);
                return result;
            }),
        );
    });

    thunkRoles(roles: Record<string, unknown>): AuthUserResponseRoles {
        //@ts-ignore
        if (roles["System Admin"]) {
            //@ts-ignore
            roles.System_Admin = roles["System Admin"];
            //@ts-ignore
            delete roles["System Admin"];
        }
        //@ts-ignore
        if (roles["System Auditor"]) {
            //@ts-ignore
            roles.System_Auditor = roles["System Auditor"];
            //@ts-ignore
            delete roles["System Auditor"];
        }
        //@ts-ignore
        if (roles["Account Admin"]) {
            //@ts-ignore
            roles.Account_Admin = roles["Account Admin"];
            //@ts-ignore
            delete roles["Account Admin"];
        }
        //@ts-ignore
        if (roles["Account Auditor"]) {
            //@ts-ignore
            roles.Account_Auditor = roles["Account Auditor"];
            //@ts-ignore
            delete roles["Account Auditor"];
        }
        //@ts-ignore
        if (roles["Domain Validation"]) {
            //@ts-ignore
            roles.Domain_Validation = roles["Domain Validation"];
            //@ts-ignore
            delete roles["Domain Validation"];
        }
        //@ts-ignore
        if (roles["Organization Admin"]) {
            //@ts-ignore
            roles.Organization_Admin = roles["Organization Admin"];
            //@ts-ignore
            delete roles["Organization Admin"];
        }
        //@ts-ignore
        if (roles["Organization Auditor"]) {
            //@ts-ignore
            roles.Organization_Auditor = roles["Organization Auditor"];
            //@ts-ignore
            delete roles["Organization Auditor"];
        }
        return roles;
    }

    setPrimaryRole(appUser: AppUser): void {
        if (appUser.systemRoles.admin) {
            appUser.primaryRole = PrimaryRole.SYSTEM_ADMIN;
        } else if (appUser.systemRoles.auditor) {
            appUser.primaryRole = PrimaryRole.SYSTEM_AUDITOR;
        } else if (appUser.accountAnyRoles.admin) {
            appUser.primaryRole = PrimaryRole.ACCOUNT_ADMIN;
        } else if (appUser.accountAnyRoles.auditor) {
            appUser.primaryRole = PrimaryRole.ACCOUNT_AUDITOR;
        } else if (appUser.organizationAnyRoles.admin) {
            appUser.primaryRole = PrimaryRole.ORGANIZATION_ADMIN;
        } else if (appUser.organizationAnyRoles.auditor) {
            appUser.primaryRole = PrimaryRole.ORGANIZATION_AUDITOR;
        } else if (appUser.organizationAnyRoles.requestor) {
            appUser.primaryRole = PrimaryRole.REQUESTOR;
        } else if (appUser.accountAnyRoles.domainValidator) {
            appUser.primaryRole = PrimaryRole.DOMAIN_VALIDATOR;
        } else {
            appUser.primaryRole = PrimaryRole.UNKNOWN;
        }
    }

    setRolesForUser(appUser: AppUser, roles: AuthUserResponseRoles): void {
        appUser.systemRoles = {
            ...DEFAULT_ROLES,
            admin: !!roles.System_Admin,
            auditor: !!roles.System_Auditor,
        };
        appUser.accountRoles = {};
        appUser.accountAnyRoles = { ...DEFAULT_ROLES };
        appUser.organizationRoles = {};
        appUser.organizationAnyRoles = { ...DEFAULT_ROLES };

        let parent: UserAccountOrganization;

        if (roles.Account_Admin) {
            appUser.accountAnyRoles.admin = true;
            for (parent of roles.Account_Admin) {
                if (!parent.accountId) continue;
                appUser.accountRoles[parent.accountId] = { ...DEFAULT_ROLES, admin: true };
            }
        }
        if (roles.Account_Auditor) {
            appUser.accountAnyRoles.auditor = true;
            for (parent of roles.Account_Auditor) {
                if (!parent.accountId) continue;
                if (!(parent.accountId in appUser.accountRoles)) {
                    appUser.accountRoles[parent.accountId] = { ...DEFAULT_ROLES };
                }
                appUser.accountRoles[parent.accountId].auditor = true;
            }
        }
        if (roles.Domain_Validation) {
            appUser.accountAnyRoles.domainValidator = true;
            for (parent of roles.Domain_Validation) {
                if (!parent.accountId) continue;
                if (!(parent.accountId in appUser.accountRoles)) {
                    appUser.accountRoles[parent.accountId] = { ...DEFAULT_ROLES };
                }
                appUser.accountRoles[parent.accountId].domainValidator = true;
            }
        }
        if (roles.Organization_Admin) {
            appUser.organizationAnyRoles.admin = true;
            for (parent of roles.Organization_Admin) {
                if (!parent.organizationId) continue;
                appUser.organizationRoles[parent.organizationId] = { ...DEFAULT_ROLES, admin: true };
            }
        }
        if (roles.Organization_Auditor) {
            appUser.organizationAnyRoles.auditor = true;
            for (parent of roles.Organization_Auditor) {
                if (!parent.organizationId) continue;
                if (!(parent.organizationId in appUser.organizationRoles)) {
                    appUser.organizationRoles[parent.organizationId] = { ...DEFAULT_ROLES };
                }
                appUser.organizationRoles[parent.organizationId].auditor = true;
            }
        }
        if (roles.Requestor) {
            appUser.organizationAnyRoles.requestor = true;
            for (parent of roles.Requestor) {
                if (!parent.organizationId) continue;
                if (!(parent.organizationId in appUser.organizationRoles)) {
                    appUser.organizationRoles[parent.organizationId] = { ...DEFAULT_ROLES };
                }
                appUser.organizationRoles[parent.organizationId].requestor = true;
            }
        }
    }

    constructor(
        @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
        @Inject(DOCUMENT) private document: Document,
        private authService: AuthService,
        private actions$: Actions,
        private router: Router,
        private apiConfiguration: Configuration,
        private globalStore: Store<{ appUser: AppUser }>,
        private usersService: UsersService,
        private redirectorService: RedirectorService,
    ) {
        this.authService.user$.subscribe((authUser: AuthUser | null | undefined) => {
            if (authUser) {
                this.usersService.usersAuthPost({ auth0UserId: authUser.sub, email: authUser.email }).subscribe(
                    (result) => {
                        const appUser: AppUser = {
                            ...authUser,
                            id: result.details?.id,
                            auth0UserId: authUser.sub,
                            firstName: result.details?.firstName ?? "",
                            lastName: result.details?.lastName ?? "",
                            authSource: result.details?.authSource ?? "",
                            authSourceType: result.details?.authSourceType ?? "",
                            systemRoles: DEFAULT_ROLES,
                            accountAnyRoles: DEFAULT_ROLES,
                            organizationAnyRoles: DEFAULT_ROLES,
                            accountRoles: {},
                            organizationRoles: {},
                            validators: [],
                        };
                        if (result.roles) {
                            this.setRolesForUser(appUser, this.thunkRoles(result.roles as Record<string, unknown>));
                        }
                        this.setPrimaryRole(appUser);
                        if ("validators" in result) {
                            // @ts-ignore
                            appUser.validators = result["validators"] as DomainValidator[];
                        }
                        globalStore.dispatch(AppUserActions.setUser({ appUser }));
                    },
                    (errorResult) => {
                        this.redirectorService.errorMsg = errorResult.error.message;
                        this.router.navigate(["/logout"]);
                    },
                );
            } else {
                globalStore.dispatch(AppUserActions.setUser({ appUser: null }));
            }
        });
        this.authService.idTokenClaims$.subscribe((result) => {
            if (result) {
                this.apiConfiguration.credentials = { OAuth2AuthorizationCodeBearer: result.__raw };
            }
        });
    }
}
