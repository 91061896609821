import { Component, Input, Output } from "@angular/core";
import { IconsService } from "../../../../../services/icons/icons.service";
import { MatDrawer } from "@angular/material/sidenav";
import { FormGroup } from "@angular/forms";
import { EventEmitter } from "@angular/core";

@Component({
    selector: "app-filters",
    templateUrl: "./filters.component.html",
    styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent {
    @Input() title = "";
    @Input() sideNav: MatDrawer | undefined;
    @Input() filterForm: FormGroup = new FormGroup({});
    @Output() setFilters = new EventEmitter<null>();

    constructor(public icons: IconsService) {}
}
