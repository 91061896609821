<h2 mat-dialog-title>View Log</h2>
<mat-dialog-content>
    <div class="flex flex-col gap-4">
        <div class="stacked-label">
            <label>Date</label>
            {{ log.createdAt | date: "medium" }}
        </div>
        <div class="flex flex-col sm:flex-row gap-4">
            <div class="stacked-label basis-1/2">
                <label>Email</label>
                {{ log.email }}
            </div>
            <div class="stacked-label basis-1/2">
                <label>Event</label>
                {{ log.type }}
            </div>
        </div>
        <div class="stacked-label">
            <label>Description</label>
            {{ log.description }}
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" [mat-dialog-close]="false">Close</button>
</mat-dialog-actions>
