import { Component } from "@angular/core";
import { FormControl, Validators, ValidationErrors, ValidatorFn, AbstractControl, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { ProfileService } from "@hydrantid/acm-client";
import { MessageService } from "../../services/message/message.service";
import { Store } from "@ngrx/store";
import { AppUser, AppUserSelectors } from "../../modules/shared/app-user";
import { first } from "rxjs/operators";

@Component({
    selector: "app-dialog-manage-password",
    templateUrl: "./dialog-manage-password.component.html",
    styleUrls: ["./dialog-manage-password.component.scss"],
})
export class DialogManagePasswordComponent {
    private auth0UserId: string | undefined;

    checkPasswords(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.form) {
                return null;
            }
            const password = this.form.controls.newPassword.value;
            const confirmPassword = control.value;
            if (password !== confirmPassword) {
                control.setErrors({ no_match: true });
                return { no_match: true };
            }
            control.setErrors({});
            return {};
        };
    }

    form = new FormGroup({
        email: new FormControl<string | undefined>({ value: undefined, disabled: true }, { nonNullable: true }),
        currentPassword: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
        newPassword: new FormControl<string>("", { nonNullable: true, validators: [Validators.required] }),
        confirmPassword: new FormControl<string>("", {
            nonNullable: true,
            validators: [Validators.required, this.checkPasswords()],
        }),
    });

    constructor(
        private dialogRef: MatDialogRef<DialogManagePasswordComponent>,
        private profilesService: ProfileService,
        private messageService: MessageService,
        private globalStore: Store<{ appUser: AppUser }>,
    ) {
        this.form.controls.newPassword.valueChanges.subscribe(() => {
            this.form.controls.confirmPassword.updateValueAndValidity();
        });
        this.globalStore
            .select(AppUserSelectors.user)
            .pipe(first())
            .subscribe((user) => {
                if (user) {
                    this.form.controls.email.setValue(user.email);
                    this.auth0UserId = user.auth0UserId;
                }
            });
    }

    savePassword(): void {
        if (this.auth0UserId) {
            const values = this.form.getRawValue();
            this.profilesService
                .profileChangePasswordPost({
                    auth0_user_id: this.auth0UserId,
                    old_password: values.currentPassword,
                    new_password: values.newPassword,
                    email: values.email,
                })
                .subscribe(
                    (result) => {
                        if (this.messageService.showResult(result, "Password updated successfully")) {
                            this.dialogRef.close();
                        }
                    },
                    (errorResult) => this.messageService.showResult(errorResult.error),
                );
        }
    }
}
