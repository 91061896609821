import { ActionReducer } from "@ngrx/store/src/models";
import { Action, createReducer, on } from "@ngrx/store";
import { PolicyActions } from "./actions";
import { PolicyState } from "../state";

export const initialState: PolicyState = {
    policies: [],
    importedPolicies: [],
};

const _featureReducer: ActionReducer<PolicyState> = createReducer<PolicyState>(
    initialState,
    on(PolicyActions.setPolicies, (state, { policies }) => {
        return { ...state, policies };
    }),
    on(PolicyActions.setImportedPolicies, (state, { importedPolicies }) => {
        return { ...state, importedPolicies };
    }),
);

export function featureReducer(state: PolicyState, action: Action): PolicyState {
    return _featureReducer(state, action);
}
