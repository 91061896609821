import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, ReplaySubject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { AppUserActions } from "../store/actions";
import { RedirectorService } from "../services/redirector.service";
import { AppUser, PagePermissions } from "../models/app-user";
import { AppUserSelectors } from "../index";

@Injectable({ providedIn: "root" })
export class AuthGuard {
    constructor(
        private globalStore: Store<{ appUser: AppUser }>,
        private redirectorService: RedirectorService,
        private router: Router,
    ) {}

    isPermittedForRoute(pagePermissions: PagePermissions, url: string): boolean {
        //@ts-ignore
        return !!pagePermissions[url];
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        /*
        There is something really funky with component store using the user global store and
        this guard, where we have to not only use a subject instead of returning via a map,
        but we also have to move it out of band using a timer
         */
        const subject: ReplaySubject<boolean> = new ReplaySubject<boolean>();
        this.globalStore
            .select(AppUserSelectors.pagePermissions)
            .pipe(
                filter((pagePermissions: PagePermissions | null | undefined) => {
                    return pagePermissions !== undefined;
                }),
                takeUntil(subject),
            )
            .subscribe((pagePermissions) => {
                //@ts-ignore
                if (!pagePermissions) {
                    // user is not signed in, go to auth0
                    this.globalStore.dispatch(AppUserActions.login({ redirect: state.url }));
                    return subject.next(false);
                }
                if (this.redirectorService.followRedirectIfSet()) {
                    return subject.next(false);
                }
                if (!this.isPermittedForRoute(pagePermissions, route.url[0].path)) {
                    if (route.data.notAllowedRedirectTo) {
                        this.router.navigate([route.data.notAllowedRedirectTo]);
                    }
                    return subject.next(false);
                }
                /* can't call next directly, need to move it out of band using a timer */
                setTimeout(() => subject.next(true), 0);
            });
        return subject.asObservable();
    }
}
