import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "formatUserEmail",
})
export class FormatUserEmailPipe implements PipeTransform {
    transform(user: { email?: string } | null): string {
        return user?.email ?? "";
    }
}
