import { Component, Inject, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { IconsService } from "../../services/icons/icons.service";
import { AppUser, AppUserActions, AppUserSelectors } from "../../modules/shared/app-user";
import { Observable, ReplaySubject } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { DialogsService } from "../../modules/shared/dialogs";
import { filter, first, map, withLatestFrom } from "rxjs/operators";
import { Strings } from "src/strings/strings";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { DialogManagePasswordComponent } from "../dialog-manage-password/dialog-manage-password.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "../../../configuration";
import { PagePermissions } from "../../modules/shared/app-user/models/app-user";

/** Component opened inside a snackbar. */
@Component({
    selector: "countdown-snackbar",
    template: `You will be logged out in {{ countdown | async }} seconds.`,
})
export class IdleSnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public countdown: Observable<number>) {}
}

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    @ViewChild("sidenav", { static: true }) sidenav: MatSidenav | undefined;

    strings = Strings;
    appTitle = `${this.strings.appLongName}${environment.appTitleSuffix}`;
    appUser$: Observable<AppUser | null | undefined> = this.globalStore.select(AppUserSelectors.user);
    pagePermissions$: Observable<PagePermissions | null | undefined> = this.globalStore.select(
        AppUserSelectors.pagePermissions,
    );

    minSideNav = false;
    isHandset$: Observable<boolean> = this.breakpointObserver
        .observe([Breakpoints.XSmall, Breakpoints.Small])
        .pipe(map((result) => result.matches));
    idleCountdown: ReplaySubject<number> = new ReplaySubject(1);
    idleSnackbar: MatSnackBarRef<IdleSnackbarComponent> | undefined = undefined;
    allowTlsDiscovery: boolean = environment.allowTlsDiscovery;

    constructor(
        private globalStore: Store<{ appUser: AppUser }>,
        public icons: IconsService,
        private dialogsService: DialogsService,
        private breakpointObserver: BreakpointObserver,
        private titleService: Title,
        private router: Router,
        private idle: Idle,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
    ) {
        this.titleService.setTitle(this.appTitle);
        router.events
            .pipe(
                withLatestFrom(this.isHandset$),
                filter(([routerEvent, isHandSet]) => isHandSet && routerEvent instanceof NavigationEnd),
            )
            .subscribe(() => {
                this.sidenav?.close();
            });
        this.setupIdle();
    }

    idleEnd(): void {
        this.snackbar.dismiss();
    }

    idleTimeout(): void {
        this.globalStore
            .select(AppUserSelectors.user)
            .pipe(first())
            .subscribe((appUser) => {
                if (appUser) {
                    this.globalStore.dispatch(AppUserActions.logout());
                }
            });
        this.snackbar.dismiss();
    }

    idleWarning(countdown: number): void {
        this.globalStore
            .select(AppUserSelectors.user)
            .pipe(first())
            .subscribe((appUser) => {
                if (appUser) {
                    this.idleCountdown.next(countdown);
                    this.openIdleWarning();
                }
            });
    }

    openIdleWarning(): void {
        if (this.idleSnackbar === undefined) {
            this.idleSnackbar = this.snackbar.openFromComponent(IdleSnackbarComponent, {
                data: this.idleCountdown.asObservable(),
                horizontalPosition: "right",
                verticalPosition: "top",
            });
            this.idleSnackbar.afterDismissed().subscribe(() => {
                this.idleSnackbar = undefined;
            });
        }
    }

    setupIdle(): void {
        this.idle.setIdle(environment.inactivityTime);
        this.idle.setTimeout(environment.timeoutWarning);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => this.idleEnd());
        this.idle.onTimeout.subscribe(() => this.idleTimeout());
        this.idle.onTimeoutWarning.subscribe((countdown) => this.idleWarning(countdown));
        this.idle.watch();
    }

    handleLogout(): void {
        this.globalStore.dispatch(AppUserActions.logout());
    }

    managePassword(): void {
        this.dialog.open(DialogManagePasswordComponent, {
            width: "400px",
        });
    }
}
