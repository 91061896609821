import { BaseStrings } from "./base_strings";

export const Strings: BaseStrings = {
    appLongName: "Zero Touch PKI",
    appShortName: "Zero Touch PKI",
    rootCssClass: "ztp",
    supportEmail: "ztpki.support@venafi.com",
    helpDocuments: {
        pdf: {
            url: "https://help.ztpki.venafi.com/ZeroTouch_ACM_Manual.pdf",
            label: "View PDF",
        },
        html: {
            url: "https://help.ztpki.venafi.com/",
            label: "View website",
        },
        video: {
            url: "https://www.youtube.com/user/Venafi",
            label: "View Videos",
        },
    },
    chartColors: [
        {
            backgroundColor: "#b9edc7",
            borderColor: "#14c345",
            pointBackgroundColor: "#14c345",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "#0bae2e",
        },
    ],
    endpointsUrl: "https://help.ztpki.venafi.com/intune/azure_endpoints.json",
};
