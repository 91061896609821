import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UsersActions } from "./actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { SortDirectionEnum, UsersService } from "@hydrantid/acm-client";
import { AppUser, AppUserSelectors } from "../../app-user";
import { PrimaryRole } from "../../app-user/models/primary-roles";

@Injectable()
export class UserEffects {
    loadUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.loadUsers),
            mergeMap(() => {
                return this.usersServices
                    .usersPost({
                        nolimit: true,
                        offset: 0,
                        sort_type: "email",
                        sort_direction: SortDirectionEnum.ASC,
                    })
                    .pipe(
                        map((usersResponse) => {
                            return UsersActions.setUsers({ users: usersResponse.users ?? [] });
                        }),
                        catchError(() => {
                            return of({ type: "error" });
                        }),
                    );
            }),
        );
    });

    constructor(
        private actions$: Actions,
        private usersServices: UsersService,
        private globalStore: Store<{ appUser: AppUser }>,
    ) {
        this.globalStore.select(AppUserSelectors.user).subscribe((user) => {
            if (user && user.primaryRole !== PrimaryRole.DOMAIN_VALIDATOR) {
                setTimeout(() => {
                    globalStore.dispatch(UsersActions.loadUsers());
                }, 0);
            } else {
                globalStore.dispatch(UsersActions.setUsers({ users: [] }));
            }
        });
    }
}
