import { Injectable } from "@angular/core";
import { CertificateRequestFilters } from "../../modules/requests-queue/components/view/view-state";
import { CertificateFilters } from "../../modules/certificates/components/view/certificate-view-state";
import { UserFilters } from "../../modules/users/components/view/view-state";
import { LogFilters } from "../../modules/shared/logs/components/list/log-view-state";
import { DomainFilters } from "../../modules/domains/components/view/view-store";
import { CertificateAuthorityFilters } from "../../modules/certificate-authorities/components/view/view-store";
import { Organization, Policy, Account, SortDirectionEnum } from "@hydrantid/acm-client";
import { WindowService } from "../../modules/shared/window";

export interface CertificateRequestFormValues {
    account: Account;
    organization: Organization;
    policy: Policy;
}

export interface SavedPagingInformation {
    i: number;
    s: number;
    sk: string;
    sd: SortDirectionEnum;
}

export interface StoredFilters {
    filterKey: string | undefined;
    filterValue:
        | CertificateFilters
        | CertificateRequestFilters
        | LogFilters
        | UserFilters
        | DomainFilters
        | CertificateAuthorityFilters
        | Record<string, unknown>;
    requestForm: CertificateRequestFormValues | Record<string, unknown>;
    pagingKey: string | undefined;
    pagingValue: SavedPagingInformation | undefined;
}

@Injectable({
    providedIn: "root",
})
export class FilterStorageService {
    private storedFilters: StoredFilters = {
        filterKey: undefined,
        filterValue: {},
        requestForm: {},
        pagingKey: undefined,
        pagingValue: undefined,
    };

    constructor(private windowService: WindowService) {
        this.initializeStorage();
    }

    clear(): void {
        localStorage.clear();
    }

    private initializeStorage(): void {
        const filterString = localStorage.getItem("storedState");
        if (filterString) {
            try {
                this.storedFilters = JSON.parse(filterString);
            } catch {
                return;
            }
        }
    }

    storeValues(): void {
        localStorage.setItem("storedState", JSON.stringify(this.storedFilters));
    }

    getFilter(
        filterKey: string,
    ):
        | CertificateFilters
        | CertificateRequestFilters
        | LogFilters
        | UserFilters
        | DomainFilters
        | CertificateAuthorityFilters
        | Record<string, unknown> {
        if (this.storedFilters.filterKey !== filterKey) {
            return {};
        }
        return this.storedFilters.filterValue;
    }

    setFilter(
        name: string,
        filterRecord:
            | CertificateFilters
            | CertificateRequestFilters
            | UserFilters
            | LogFilters
            | DomainFilters
            | CertificateAuthorityFilters,
    ): void {
        this.storedFilters.filterKey = name;
        this.storedFilters.filterValue = filterRecord;
        this.storeValues();
    }

    setPaging(pagingKey: string | undefined, data: SavedPagingInformation): void {
        if (!pagingKey) {
            return;
        }
        this.storedFilters.pagingKey = pagingKey;
        this.storedFilters.pagingValue = data;
        this.storeValues();
    }

    getPaging(pagingKey: string | undefined): SavedPagingInformation | undefined {
        if (!pagingKey || pagingKey != this.storedFilters.pagingKey) {
            return undefined;
        }
        return this.storedFilters.pagingValue;
    }

    setCertificateRequestForm(requestForm: CertificateRequestFormValues): void {
        this.storedFilters.requestForm = requestForm;
        this.storeValues();
    }

    getCertificateRequestForm(): CertificateRequestFormValues | Record<string, unknown> {
        return this.storedFilters.requestForm;
    }
}
