import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IconsService } from "../../../../../services/icons/icons.service";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDrawer } from "@angular/material/sidenav";

export interface FilterValue {
    control: FormControl | FormControl[];
    value: string;
    label: string;
}

@Component({
    selector: "app-filter-values",
    templateUrl: "./filter-values.component.html",
    styleUrls: ["./filter-values.component.scss"],
})
export class FilterValuesComponent {
    @Input() filterForm: FormGroup | undefined;
    @Input() sideNav: MatDrawer | undefined;
    @Input() values: FilterValue[] = [];
    @Output() setFilters = new EventEmitter<null>();

    constructor(public icons: IconsService) {}

    clearFilterValue(value: FilterValue): void {
        if (value.control instanceof FormControl) {
            value.control.setValue("");
        } else {
            for (const control of value.control) {
                control.setValue("");
            }
        }
        this.setFilters.emit();
    }
}
