import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { LoaderInterceptorService } from "./interceptors/loader.interceptor";
import { LoaderComponent } from "./component/loader.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

export { SHOW_LOADING_FOR_GET } from "./tokens";

@NgModule({
    declarations: [LoaderComponent],
    imports: [CommonModule, FontAwesomeModule, StoreModule.forFeature("loadingStatus", featureReducer)],
    exports: [LoaderComponent],
    providers: [{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true }],
})
export class LoadingStatusModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faSpinner);
    }
}
