<h2 mat-dialog-title *ngIf="dialogData.title">{{ dialogData.title }}</h2>
<mat-dialog-content>
    <div [innerHTML]="dialogData.message"></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button color="primary" *ngIf="!dialogData.hideCancelButton" [mat-dialog-close]="false">
        {{ dialogData.cancelTitle || "Cancel" }}
    </button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{ dialogData.okayTitle || "OK" }}
    </button>
</mat-dialog-actions>
