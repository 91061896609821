import { Account, GetLogsResponse, Log, LogsService, Organization, SortDirectionEnum } from "@hydrantid/acm-client";
import { BaseFilters, TableViewState, TableViewStore } from "../../../../../shared-models/table-view-state";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppUser } from "../../../app-user";
import { Observable } from "rxjs";
import { FilterStorageService } from "../../../../../services/filter-storage/filter-storage.service";

export interface LogFilters extends BaseFilters {
    type?: string | undefined;
    description?: string | undefined;
    email?: string | undefined;
    user_id?: string | undefined;
    connection?: string | undefined;
    start_date?: Date | undefined;
    end_date?: Date | undefined;
    certificate_id?: string | undefined;
    organization_id?: Organization | undefined;
    account_id?: Account | undefined;
    cert_request_id?: string | undefined;
}

@Injectable()
export class LogViewStore extends TableViewStore<Log, LogFilters, LogsService> {
    constructor(
        protected service: LogsService,
        protected globalStore: Store<{ appUser: AppUser }>,
        protected filterStorageService: FilterStorageService,
    ) {
        super(service, globalStore, filterStorageService);
    }

    shouldDeferLoad(): boolean {
        return true;
    }

    static payloadFromFilters(filters: LogFilters): Record<string, string | undefined> {
        const results: Record<string, string | undefined> = {
            ...filters,
            account_id: undefined,
            organization_id: undefined,
            start_date: undefined,
            end_date: undefined,
        };
        if ("start_date" in filters && filters.start_date) {
            results.start_date = new Date(filters.start_date).toISOString();
        } else {
            delete results.start_date;
        }
        if ("end_date" in filters && filters.end_date) {
            results.end_date = new Date(filters.end_date).toISOString();
        } else {
            delete results.end_date;
        }
        if (filters.account_id) {
            results.account_id = filters.account_id.id;
        } else {
            delete results.account_id;
        }
        if (filters.organization_id) {
            results.organization_id = filters.organization_id.id;
        } else {
            delete results.organization_id;
        }
        return results;
    }
    internalLoadItems(state: TableViewState<Log, LogFilters>): void {
        let serviceCall: Observable<GetLogsResponse>;

        if (state.filters.certificate_id) {
            //variation of log, get from
            serviceCall = this.service.logsCertificateIdPost(state.filters.certificate_id, {
                sort_type: state.sorting.sortType,
                sort_direction: state.sorting.sortDirection,
            });
        } else {
            serviceCall = this.service.logsPost({
                ...LogViewStore.payloadFromFilters(state.filters),
                limit: state.paging.pageSize,
                offset: state.paging.pageIndex * state.paging.pageSize,
                sort_type: state.sorting.sortType,
                sort_direction: state.sorting.sortDirection,
            });
        }
        serviceCall.subscribe((results) => {
            this.setItemCount(results.count || 0);
            this.setItems(results.items || []);
        });
    }

    setDefaultSort(): void {
        this.setSort({ sortType: "createdAt", sortDirection: SortDirectionEnum.DESC });
    }
}
