import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { EnvironmentStatusActions } from "./actions";
import { catchError, map, mergeMap } from "rxjs/operators";
import { of } from "rxjs";
import { EnvironmentStatus } from "../models/environment-status";
import { HttpClient } from "@angular/common/http";
import { ENVIRONMENT_STATUS_CONFIG, EnvironmentStatusConfig } from "../tokens";
import { Action } from "@ngrx/store";

@Injectable()
export class EnvironmentStatusEffects implements OnInitEffects {
    getStatus$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(EnvironmentStatusActions.get),
            mergeMap(() => {
                if (!this.config.fileUrl) {
                    return of({ type: "not_configured" });
                }
                return this.http.get(this.config.fileUrl).pipe(
                    map((statuses) => {
                        // @ts-ignore
                        const status: EnvironmentStatus | null = statuses[this.config.envName] ?? null;
                        return EnvironmentStatusActions.set({ status });
                    }),
                    catchError(() => {
                        return of({ type: "error" });
                    }),
                );
            }),
        );
    });

    ngrxOnInitEffects(): Action {
        return EnvironmentStatusActions.get();
    }

    constructor(
        @Inject(ENVIRONMENT_STATUS_CONFIG) private config: EnvironmentStatusConfig,
        private actions$: Actions,
        private http: HttpClient,
    ) {}
}
