import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { featureReducer } from "./store/reducer";
import { EffectsModule } from "@ngrx/effects";
import { EnvironmentStatusEffects } from "./store/effects";

import * as EnvironmentStatusSelectors from "./store/selectors";
import { EnvironmentStatusComponent } from "./components/environment-status/environment-status.component";
import { CommonModule } from "@angular/common";
import { LetDirective } from "@ngrx/component";
export { EnvironmentStatusSelectors };
export { EnvironmentStatusActions } from "./store/actions";
export { EnvironmentStatus } from "./models/environment-status";
export { ENVIRONMENT_STATUS_CONFIG, EnvironmentStatusConfig } from "./tokens";

@NgModule({
    imports: [
        CommonModule,
        LetDirective,
        StoreModule.forFeature("environmentStatus", featureReducer),
        EffectsModule.forFeature([EnvironmentStatusEffects]),
    ],
    declarations: [EnvironmentStatusComponent],
    exports: [EnvironmentStatusComponent],
})
export class EnvironmentStatusModule {}
