<ng-container *ngrxLet="logState.vm$; let vm">
    <div class="table-content">
        <table mat-table [dataSource]="vm.items" matSort matSortDisableClear>
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="large">Event</th>
                <td mat-cell *matCellDef="let row" class="large">{{ row.type }}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="xxlarge">Description</th>
                <td mat-cell *matCellDef="let row" class="xxlarge">{{ row.description }}</td>
            </ng-container>
            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="table-cell-align-right">When</th>
                <td mat-cell *matCellDef="let row" class="table-cell-align-right">
                    {{ row.createdAt | date: "medium" }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" class="clickable" (click)="viewLog(row)"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell no-data" colspan="100%">
                    <div
                        *ngIf="requireExplicitLoad"
                        class="alert info flex flex-col items-center place-content-center gap-4"
                    >
                        <div class="text-center">
                            The list of logs has the potential of being very large and it is recommended that you filter
                            before searching.<br />Clicking <strong>Show Logs</strong> below will display the logs based
                            on any current filters set.
                        </div>
                        <button mat-flat-button color="primary" mat-button (click)="loadLogs()">Show Logs</button>
                    </div>
                    <div *ngIf="!requireExplicitLoad">No logs match your filter</div>
                </td>
            </tr>
        </table>
    </div>
    <div class="table-content-footer flex flex-col sm:flex-row gap-3" *ngIf="!requireExplicitLoad">
        <button mat-button color="primary" (click)="downloadFiltered()" class="order-3 sm:order-1" *ngIf="showDownload">
            Download Filtered Logs
        </button>
        <span class="flex flex-auto order-2"></span>
        <div class="flex order-1 sm:order-3 items-center place-content-center" *ngIf="showPager">
            <mat-paginator
                [pageSize]="vm.pageSize"
                [pageSizeOptions]="[10, 25, 50, 100]"
                [length]="vm.itemCount"
                (page)="logState.componentPageChanged($event)"
            >
            </mat-paginator>
        </div>
    </div>
</ng-container>
